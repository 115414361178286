import Const from '@/helpers/const'
import { custAxios } from './genericApi'

export default {
  getAreas () {
    return custAxios().get(`${Const.API_AREAS}`)
  },
  getFormationsAndSubAreas (id) {
    return custAxios().get(`${Const.API_AREAS}/${id}/formationWithSubAreas`)
  }
}
