export default {
  data: () => {
    return {
      tabs: null
    }
  },
  mounted () {
    this.tabs = this.selectTab()
  },
  methods: {
    goBackToHome () {
      this.$router.push({ name: 'Home' })
    },
    changeRoute (route) {
      if (this.$route.name !== route) {
        this.$router.push({ name: route })
      }
    },
    selectTab () {
      return {
        Courses: 0,
        Disciplines: 1,
        Coordinator: 2,
        Professor: 3
      }[this.$route.name]
    }
  }
}
