import { mapActions } from 'vuex'
import htmlHelper from '../../../helpers/html'
import statusHelper from '../../../helpers/status'
import { ADD_TOAST_MESSAGE } from 'vuex-toast'

export default {
  components: {
  },
  data () {
    return {
      scrollBtn: false,
      courses: [],
      page: 1,
      totalPages: null,
      sortBy: [
        {
          name: 'Curso',
          value: 'coursename'
        },
        {
          name: 'Disciplina',
          value: 'disciplinename'
        }
      ],
      sortDirection: [
        {
          name: 'Crescente',
          value: 'asc'
        },
        {
          name: 'Decrescente',
          value: 'desc'
        }
      ],
      courseName: '',
      disciplineName: '',
      isLoading: false,
      isLoadingExport: false
    }
  },
  mounted () {
    this.isLoading = true
    this.search()
  },
  methods: {
    ...mapActions([
      'actionGetOrganizationChart',
      'actionGetOrganizationChartExport'
    ]),
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    searchText () {
      this.isLoading = true
      this.page = 1
      this.search()
    },
    search () {
      this.isLoading = true
      const params = {
        courseName: this.courseName ? this.courseName : '',
        disciplineName: this.disciplineName ? this.disciplineName : '',
        page: this.page,
        rowsPerPage: 100
      }

      this.actionGetOrganizationChart(params)
        .then((response) => {
          this.data = response

          if (response.total === 0) {
            this.page = 0
          }

          this.totalPages = Math.ceil(response.total / params.rowsPerPage)
          this.courses = response.courses
          this.isLoading = false
        }).catch((error) => {
          this.addToast({
            text: error.message,
            type: 'danger'
          })
          this.isLoading = false
        })
    },
    exportOrganizationChart () {
      const params = {
        courseName: this.courseName ? this.courseName : '',
        disciplineName: this.disciplineName ? this.disciplineName : ''
      }

      this.isLoadingExport = true
      this.actionGetOrganizationChartExport(params)
        .then((response) => {
          let csv = this.$papa.unparse(response, {
            quotes: false,
            quoteChar: '"',
            escapeChar: '"',
            delimiter: ';',
            header: false,
            newline: '\r\n',
            skipEmptyLines: false,
            columns: null
          })

          // Convert UTF-8 and HTML
          const header = 'Curso;Disciplina;Situação;Coordenador(a);Professores(as);Qtd. UAs;Código da UA;UA;LTI\r\n'
          csv = '\uFEFF' + header + htmlHelper.decode(csv)
          this.$papa.download(csv, 'organograma')
          this.isLoadingExport = false
        }).catch((error) => {
          this.addToast({
            text: error.message,
            type: 'danger'
          })

          this.isLoadingExport = false
        })
    },
    changePagination () {
      this.search()
    },
    formatNameUa (name) {
      return htmlHelper.decode(name)
    },
    getColor (status) {
      return statusHelper.getColorStatus(status)
    },
    getIconStatus (status) {
      return statusHelper.getIconStatus(status)
    },
    getTextStatus (status, motive = null) {
      if (motive && motive !== null) {
        return statusHelper.getTextStatus(status, motive)
      } else {
        return statusHelper.getTextStatus(status)
      }
    },
    onScroll (e) {
      if (typeof window === 'undefined') {
        return null
      }

      const top = window.pageYOffset || e.target.scrollTop || 0
      this.scrollBtn = top > 20
    },
    toTop () {
      this.$vuetify.goTo(0)
    },
    clearFilterCourseName () {
      this.courseName = ''
      this.searchText()
    },
    clearFilterDisciplineName () {
      this.disciplineName = ''
      this.searchText()
    }
  }
}
