import Vue from 'vue'
import VueRouter from 'vue-router'
import {
  Home,
  Login,
  Public,
  Private,
  AlternateLogin,
  OrganizationChart,
  Configurations,
  Course,
  Discipline,
  Coordinator,
  Professor,
  MyDisciplines,
  CurrentDisciplines,
  HistoricDisciplines,
  MyUnits
} from '@/screens'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'Public',
    redirect: '/login',
    component: Public,
    beforeEnter: (to, from, next) => {
      if (Vue.prototype.$globalStore.getters.getterAccount && Object.keys(Vue.prototype.$globalStore.getters.getterAccount).length > 0 && to.name !== 'Alternate-Login') {
        next({ name: 'Private' })
      } else {
        next()
      }
    },
    children: [
      {
        path: '/login',
        name: 'Login',
        component: Login
      },
      {
        path: '/alternatelogin/:username/:password',
        name: 'Alternate-Login',
        component: AlternateLogin
      }
    ]
  },
  {
    path: '/main',
    name: 'Private',
    redirect: '/home',
    component: Private,
    beforeEnter: (to, from, next) => {
      if (Vue.prototype.$globalStore.getters.getterAccount && Object.keys(Vue.prototype.$globalStore.getters.getterAccount).length > 0) {
        next()
      } else {
        next({ name: 'Public' })
      }
    },
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home
      },
      {
        path: '/config',
        name: 'Configurations',
        component: Configurations,
        redirect: { name: 'Courses' },
        beforeEnter: (to, from, next) => {
          if (Vue.prototype.$globalStore.getters.getterAccount && Object.keys(Vue.prototype.$globalStore.getters.getterAccount).length > 0 && Vue.prototype.$globalStore.getters.getterAccount.role === 'AD') {
            next()
          } else {
            next({ name: 'Home' })
          }
        },
        children: [
          {
            path: '/courses',
            name: 'Courses',
            component: Course
          },
          {
            path: '/discipline',
            name: 'Disciplines',
            component: Discipline
          },
          {
            path: '/professor',
            name: 'Professor',
            component: Professor
          },
          {
            path: '/coordinator',
            name: 'Coordinator',
            component: Coordinator
          }

        ]
      },
      {
        path: '/minhas-disciplinas',
        name: 'MyDisciplines',
        component: MyDisciplines,
        redirect: { name: 'CurentDisciplines' },
        children: [
          {
            path: '/current',
            name: 'CurentDisciplines',
            component: CurrentDisciplines
          },
          {
            path: '/historic',
            name: 'HistoricDisciplines',
            component: HistoricDisciplines
          },
          {
            path: '/organizationchart',
            name: 'OrganizationChart',
            component: OrganizationChart
          }
        ]
      },
      {
        path: '/myunits',
        name: 'MyUnits',
        component: MyUnits
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
