import Const from '@/helpers/const'
import { custAxios } from './genericApi'

export default {
  getProfessors (params) {
    return custAxios().get(`${Const.API_PROFESSORS}?search=${params.search}&sortDirection=${params.sort}&page=${params.page}&rowsPerPage=${params.rowsPerPage}`)
  },
  insertProfessor ({ name, email, disciplines }) {
    return custAxios().post(`${Const.API_PROFESSORS}`, { name, email, disciplines })
  },
  getProfessor (params) {
    return custAxios().get(`${Const.API_PROFESSORS}/${params.id}`)
  },
  updateProfessor (params) {
    return custAxios().put(`${Const.API_PROFESSORS}/${params.id}`, { name: params.name, email: params.email, disciplines: params.disciplines })
  },
  deleteProfessor (id) {
    return custAxios().delete(`${Const.API_PROFESSORS}/${id}`)
  }
}
