export default {
  name: 'itemsList',
  props: [
    'items',
    'title',
    'hintColumnName',
    'textNoneFound',
    'paginated',
    'totalPages',
    'sortTypes',
    'isLoadingItems',
    'isDisabledItems',
    'noItemsFoundText'
  ],
  data () {
    return {
      page: 1,
      search: '',
      isSearching: false,
      selectedSortType: 'asc'
    }
  },
  methods: {
    updateItem (item) {
      this.$emit('emit-update-item', item)
    },
    deleteItem (item) {
      this.$emit('emit-delete-item', item)
    },
    sortList (sort) {
      this.$emit('emit-sort-list', sort)

      this.page = 1
    },
    changePage (page) {
      this.$emit('emit-change-page', page)
    },
    filterList () {
      this.$emit('emit-filter-list', this.search)

      this.page = 1
    },
    clearFilter () {
      this.search = ''
      this.$emit('emit-filter-list', this.search)

      this.page = 1
    },
    reactivateAccount (user) {
      this.$emit('emit-reactivate-account', user)
    }
  }
}
