import { mapActions } from 'vuex'
import { ADD_TOAST_MESSAGE } from 'vuex-toast'
import FooterSagah from '@/components/footer/'
import SagahHeader from '@/components/header/'
import ModalRecoveryPassword from '@/components/modals/reset-password'

export default {
  data () {
    return {
      username: '',
      password: '',
      emailRecoveryPassword: '',
      usersRecoveryPassword: [],
      isOpenModalRecoveryPassword: false,
      isLoadingLogin: false,
      isLoadingRecoveryPassword: false,
      isRecoveryPassword: false,
      showPassword: false,
      savePassword: false
    }
  },
  components: {
    FooterSagah,
    SagahHeader,
    ModalRecoveryPassword
  },
  mounted () {
    this.validateGetPassword()
  },
  methods: {
    ...mapActions([
      'actionSignIn',
      'actionResetPassword'
    ]),
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    login () {
      this.validateSavePassword()

      const params = {
        username: this.username,
        password: this.password
      }

      this.isLoadingLogin = true

      this.actionSignIn(params)
        .then(() => {
          this.isLoadingLogin = false

          this.$router.push({ name: 'Home' })
        }).catch((error) => {
          this.addToast({
            text: error.message || error,
            type: 'danger'
          })

          this.isLoadingLogin = false
        })
    },
    validateGetPassword () {
      const storage = window.localStorage.getItem('pass-sagah')

      if (storage && JSON.parse(storage).savePassword) {
        this.username = JSON.parse(storage).username
        this.savePassword = JSON.parse(storage).savePassword
        this.password = atob(JSON.parse(storage).password)
      }
    },
    validateSavePassword () {
      if (this.savePassword) {
        const password = {
          username: this.username,
          password: btoa(this.password),
          savePassword: this.savePassword
        }

        window.localStorage.setItem('pass-sagah', JSON.stringify(password))
      } else {
        window.localStorage.removeItem('pass-sagah')
      }
    },
    recoveryPassword () {
      this.isLoadingRecoveryPassword = true
      const params = {
        email: this.emailRecoveryPassword
      }

      this.actionResetPassword(params)
        .then((response) => {
          this.isLoadingRecoveryPassword = false

          if (response.data.length > 0) {
            this.usersRecoveryPassword = response.data

            this.usersRecoveryPassword.forEach((item) => {
              item.showName = `${item.name} (${item.userid}) - ${this.verifyRole(item.role)} - ${item.ies}`
            })

            this.isOpenModalRecoveryPassword = true
          } else {
            this.cleanFieldsRecoveryPassword()

            this.addToast({
              text: 'Em breve uma nova senha será enviada em seu e-mail cadastrado.',
              type: 'success'
            })
          }
        }).catch((error) => {
          if (error === 'Usuário não encontrado') {
            this.addToast({
              text: 'Usuário não encontrado.',
              type: 'danger'
            })
          }

          this.isLoadingRecoveryPassword = false
        })
    },
    verifyRole (role) {
      if (role === 'PRF') {
        return 'Professor'
      } else if (role === 'CCR') {
        return 'Coordenador'
      } else if (role === 'AD') {
        return 'Administrador'
      } else {
        return 'Indefinido'
      }
    },
    redirectTologin () {
      this.isRecoveryPassword = !this.isRecoveryPassword
      this.cleanFieldsRecoveryPassword()
    },
    cleanFieldsRecoveryPassword () {
      this.isOpenModalRecoveryPassword = false
      this.emailRecoveryPassword = ''
      this.usersRecoveryPassword = []
    },
    swapLoginAndRecoveryPassword () {
      this.isRecoveryPassword = !this.isRecoveryPassword
      this.isLoadingLogin = false
      this.isLoadingRecoveryPassword = false
    }
  }
}
