import coordinatorsApi from '@/api/coordinators'
import flowApi from '@/api/flow'

const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
  actionGetCoordinatorsFlow ({ commit }) {
    return flowApi.getFlow()
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  },
  actionUpdateCoordinatorsFlow ({ commit }, status) {
    const params = {
      flow: 'coordinates',
      status
    }

    return flowApi.updateFlow(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  },
  actionGetCoordinators ({ commit }, params) {
    return coordinatorsApi.getCoordinators(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  },
  actionInsertCoordinator ({ commit }, { name, email, disciplines }) {
    return coordinatorsApi.insertCoordinator({ name, email, disciplines })
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  },
  actionEditCoordinator ({ commit }, params) {
    return coordinatorsApi.editCoordinator(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  },
  actionUpdateCoordinator ({ commit }, params) {
    return coordinatorsApi.updateCoordinator(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  },
  actionDeleteCoordinator ({ commit }, id) {
    return coordinatorsApi.deleteCoordinator(id)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
