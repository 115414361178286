import Const from '@/helpers/const'
import { custAxios } from './genericApi'

export default {
  getFlow () {
    return custAxios().get(`${Const.API_FLOW}`)
  },
  updateFlow (params) {
    return custAxios().put(`${Const.API_FLOW}/${params.flow}`, { status: params.status })
  }
}
