import suggestContentApi from '@/api/suggestContent'

const state = {
}
const getters = {
}
const mutations = {
}
const actions = {
  actionSendSuggestion ({ commit }, params) {
    return suggestContentApi.sendSuggestion(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
