import RequestDiscipline from '@/components/modals/request-discipline'
import { mapMutations, mapGetters } from 'vuex'
import modalUpdateLimitOfDisciplines from '@/components/modals/update-limit-uas-of-disciplines'

export default {
  data: () => {
    return {
      tabs: null,
      searchDiscipline: '',
      isOpenModalUpdateLimitUasOfSDisciplines: false,
      isOpenModalRequestNewDiscipline: false
    }
  },
  components: {
    RequestDiscipline,
    modalUpdateLimitOfDisciplines
  },
  mounted () {
    this.tabs = this.currentTab()
  },
  computed: {
    ...mapGetters([
      'getterAccount'
    ])
  },
  methods: {
    ...mapMutations([
      'SET_SEARCH_DISCIPLINE',
      'SET_CHANGE_TOTAL_LIMIT'
    ]),
    goBackToHome () {
      this.$router.push({ name: 'Home' })
    },
    changeRoute (route) {
      this.searchDiscipline = ''
      this.SET_SEARCH_DISCIPLINE('')

      this.$router.push({ name: route }, () => {
        return false
      })
    },
    currentTab () {
      return {
        CurentDisciplines: 0,
        HistoricDisciplines: 1,
        OrganizationChart: 2
      }[this.$route.name]
    },
    clearSearch () {
      this.searchDiscipline = ''
      this.SET_SEARCH_DISCIPLINE('')
    }
  },
  beforeDestroy () {
    this.clearSearch()
  }
}
