import { ADD_TOAST_MESSAGE } from 'vuex-toast'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import itemsList from '@/components/items-list'
import modalStructure from '@/components/modals/modal-structure'

export default {
  components: {
    itemsList,
    modalStructure
  },
  data () {
    return {
      useFlow: null,
      inputCourse: '',
      courses: [],
      search: '',
      sortType: 'asc',
      sortListTypes: [
        { name: 'Ordem alfabética crescente', value: 'asc' },
        { name: 'Ordem alfabética decrescente', value: 'desc' }
      ],
      selectedCourse: {
        id: '',
        name: ''
      },
      isLoadingUpdate: false,
      isLoadingCourses: false,
      isDisabledFlow: false,
      itemToDelete: null,
      alertModalSettings: {
        isOpen: false,
        icon: '',
        title: '',
        message: '',
        color: '',
        isPersistent: false,
        isConfirmLoading: false,
        textCancel: '',
        textConfirm: ''
      }
    }
  },
  mounted () {
    this.getCourses()

    this.getFlow()

    this.useFlow = !this.getterFlow
  },
  computed: {
    ...mapGetters(['getterFlow'])
  },
  methods: {
    ...mapMutations(['SET_SELECTED_COURSE', 'SET_COURSES', 'SET_FLOW']),
    ...mapActions([
      'actionGetCourses',
      'actionInsertCourse',
      'actionUpdateCourse',
      'actionDeleteCourse',
      'actionGetCourseFlow',
      'actionUpdateFlow'
    ]),
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    getFlow () {
      this.actionGetCourseFlow()
        .then((response) => {
          this.SET_FLOW(response.courses)
        })
    },
    updateFlow () {
      this.isDisabledFlow = true

      this.actionUpdateFlow(!this.useFlow)
        .then(response => {
          this.SET_FLOW(!this.useFlow)

          this.addToast({
            text: response.message,
            type: 'success'
          })

          this.isDisabledFlow = false
        })
        .catch(message => {
          this.addToast({
            text: message,
            type: 'danger'
          })
        })
    },
    getCourses () {
      this.isLoadingCourses = true

      const params = {
        search: this.search || '',
        sort: this.sortType
      }

      this.actionGetCourses(params)
        .then((response) => {
          this.courses = response
          this.isLoadingCourses = false
        })
    },
    editCourse (item) {
      this.$refs.input.focus()

      Object.assign(this.selectedCourse, item)
    },
    insertCourse (item) {
      if (!item.name) {
        return
      }

      this.isLoadingUpdate = true

      this.actionInsertCourse(item.name)
        .then(response => {
          this.courses.push(response)

          this.addToast({
            text: 'Curso adicionado com sucesso!',
            type: 'success'
          })

          this.clearFields()

          this.isLoadingUpdate = false
        })
        .catch(message => {
          this.addToast({
            text: message,
            type: 'danger'
          })
        })
        .finally(() => {
          this.isLoadingUpdate = false
        })
    },
    updateCourse () {
      if (!this.selectedCourse.name) {
        return
      }

      this.isLoadingUpdate = true

      this.actionUpdateCourse(this.selectedCourse)
        .then(() => {
          const courseToReplace = this.courses.find(
            course => course.id === this.selectedCourse.id
          )

          Object.assign(courseToReplace, this.selectedCourse)

          this.addToast({
            text: 'Curso atualizado com sucesso!',
            type: 'success'
          })

          this.clearFields()
        })
        .catch(message => {
          this.addToast({
            text: message,
            type: 'danger'
          })
        })
        .finally(() => {
          this.isLoadingUpdate = false
        })
    },
    deleteItem (id) {
      this.alertModalSettings.isConfirmLoading = true

      this.actionDeleteCourse(id)
        .then(response => {
          this.alertModalSettings.isOpen = false
          this.courses = this.courses.filter(course => course.id !== id)

          this.addToast({
            text: response.message,
            type: 'success'
          })

          if (id === this.selectedCourse.id) {
            this.clearFields()
          }
        })
        .catch(message => {
          this.addToast({
            text: message,
            type: 'danger'
          })
        })
        .finally(() => {
          this.alertModalSettings.isConfirmLoading = false
        })
    },
    filterItems (query) {
      this.search = query
      this.getCourses()
    },
    sortItems (type) {
      this.sortType = type
      this.getCourses()
    },
    clearFields () {
      this.selectedCourse = {
        id: '',
        name: ''
      }

      this.inputCourse = ''
    },
    openConfirmModal (item) {
      this.itemToDelete = item.id

      this.alertModalSettings = {
        isOpen: true,
        icon: '',
        color: 'primary',
        isPersistent: true,
        message: `<span>Confirma a exclusão do Curso: <b>${item.name}</b>? </span>`,
        title: 'Confirmação de Exclusão',
        isConfirmLoading: false,
        textCancel: 'Cancelar',
        textConfirm: 'Excluir'
      }
    },
    closeAlertModal () {
      this.alertModalSettings.isOpen = false
      this.alertModalSettings.isConfirmLoading = false
    }
  }
}
