import organizationChartApi from '@/api/organizationChart'

const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
  actionGetOrganizationChart ({ commit }, params) {
    return organizationChartApi.getOrganizationChart(params.courseName, params.disciplineName, params.page, params.rowsPerPage)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.response.data)
      })
  },
  actionGetOrganizationChartExport ({ commit }, params) {
    return organizationChartApi.getOrganizationChartExport(params.courseName, params.disciplineName)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.response.data)
      })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
