import axios from 'axios'
import Const from '@/helpers/const'
import { custAxios } from './genericApi'

export default {
  signIn (username, password) {
    return axios.post(`${Const.API_URL}/session`, {
      username,
      password
    })
  },
  alternateSignIn (params) {
    return axios.post(`${Const.API_URL}/alternatesession`, params)
  },
  resetPassword (userid, email) {
    return axios.post(`${Const.API_URL}/user/lostPassword`, {
      userid,
      email
    })
  },
  changePassword (oldPassword, newPassword, newPasswordConfirmation) {
    return custAxios().post(`${Const.API_USER}/changePassword`, {
      oldPassword,
      newPassword,
      newPasswordConfirmation
    })
  },
  reactivateUser (id) {
    return custAxios().put(`${Const.API_URL}/user/active/${id}`)
  },
  getUserInfo () {
    return custAxios().get(`${Const.API_URL}/user/me`)
  },
  migration (params) {
    return custAxios().post(`${Const.API_URL}/user/migration`, params)
  }
}
