import { mapActions } from 'vuex'
import { ADD_TOAST_MESSAGE } from 'vuex-toast'

export default {
  props: [
    'isOpen',
    'disciplineToDisapprove'
  ],
  data () {
    return {
      reason: '',
      isLoading: false
    }
  },
  methods: {
    ...mapActions([
      'actionPutDisapproveDiscipline'
    ]),
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    giveBackDiscipline () {
      this.isLoading = true

      const params = {
        disciplineId: this.disciplineToDisapprove.disciplineid,
        motive: this.reason
      }

      this.actionPutDisapproveDiscipline(params)
        .then(() => {
          this.addToast({
            text: 'Disciplina devolvida com sucesso.',
            type: 'success'
          })

          this.$emit('emit-confirm', this.reason)

          this.isLoading = false
          this.reason = ''
        }).catch(() => {
          this.addToast({
            text: 'Houve um erro ao devolver a disciplina, por favor tente mais tarde.',
            type: 'danger'
          })

          this.isLoading = false
          this.reason = ''
        })
    },
    closeModal () {
      this.reason = ''
      this.$emit('emit-cancel')
    }
  }
}
