import timelineApi from '@/api/timeline'

const state = {
}
const getters = {
}
const mutations = {
}
const actions = {
  actionUpdateTimeline (_, params) {
    return timelineApi.updateTimeline(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.response.data)
      })
  },
  actionUpdateTimelineV2 (_, params) {
    return timelineApi.updateTimelineV2(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.response.data)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
