import { mapActions, mapGetters } from 'vuex'
import { groupBy } from 'lodash'

export default {
  data () {
    return {
      areaIndex: null,
      isLoading: false,
      isLoadingAreas: false,
      selectedSubAreas: [],
      drawer: null,
      isMiniDrawer: true,
      search: ''
    }
  },
  mounted () {
    this.getAreas()
  },
  watch: {
    areaIndex (newIndex) {
      if (newIndex || newIndex === 0) {
        this.getFormationsAndSubAreas(newIndex)
      }
    }
  },
  computed: {
    ...mapGetters([
      'getterAreas',
      'getterAreaFormations'
    ]),
    refatoredList () {
      return this.getterAreas.filter((item) => {
        return item.area.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  methods: {
    ...mapActions([
      'actionGetAreas',
      'actionGetFormationsAndSubAreas'
    ]),
    openDrawer () {
      if (window.innerWidth <= 600) {
        this.handleMobile()
      }

      this.isMiniDrawer = !this.isMiniDrawer
    },
    handleMobile () {
      if (!this.isMiniDrawer) {
        document.querySelector('body').classList.remove('overflow-y-hidden')
        document.querySelector('html').classList.remove('overflow-y-hidden')
      } else {
        document.querySelector('body').classList.add('overflow-y-hidden')
        document.querySelector('html').classList.add('overflow-y-hidden')
      }
    },
    getAreas () {
      this.isLoadingAreas = true

      this.actionGetAreas()
        .then(() => {
          this.isLoadingAreas = false
        }).catch(() => {
          this.isLoadingAreas = false
        })
    },
    getFormationsAndSubAreas (index) {
      this.isLoading = true

      this.actionGetFormationsAndSubAreas(this.getterAreas[index].id)
        .then(() => {
          this.isLoading = false
        }).catch(() => {
          this.isLoading = false
        })
    },
    formatedAndEmitData (items) {
      const group = groupBy(items, 'formationId')

      const groupedData = Object.entries(group).map((item) => {
        return {
          formationId: item[0],
          subAreasId: item[1].map((subArea) => subArea.id)
        }
      })

      if (groupedData.length === 0) {
        this.$emit('selectedItems', [])
      } else {
        const data = {
          geralAreaId: this.getterAreas[this.areaIndex].id,
          areaFormations: [
            ...groupedData
          ]
        }

        this.$emit('selectedItems', data)
      }
    },
    reset () {
      this.areaIndex = null
      this.selectedSubAreas = []
    }
  }
}
