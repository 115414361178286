import { ADD_TOAST_MESSAGE } from 'vuex-toast'
import { mapGetters, mapActions } from 'vuex'

export default {
  props: [
    'openModal'
  ],
  data () {
    return {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      showOldPassword: false,
      showNewPassword: false,
      isLoading: false,
      passwordValidationHint: ''
    }
  },
  computed: {
    ...mapGetters([
      'getterAccount'
    ]),
    visible () {
      return this.openModal || (this.getterAccount && this.getterAccount.firstLogin)
    }
  },
  methods: {
    ...mapActions([
      'actionChangePassword'
    ]),
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    changePassword () {
      this.isLoading = true

      const params = {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
        newPasswordConfirmation: this.confirmNewPassword
      }

      this.actionChangePassword(params)
        .then(() => {
          this.addToast({
            text: 'Senha atualizada com sucesso!',
            type: 'success'
          })

          this.cleanFields()

          this.isLoading = false

          if (this.openModal) {
            this.$emit('emit-close-modal')
          }
        }).catch(() => {
          this.addToast({
            text: 'Houve um erro ao atualizar sua senha, antiga senha está incorreta!',
            type: 'danger'
          })

          this.isLoading = false
        })
    },
    validatePasswordFields () {
      if (this.newPassword && this.confirmNewPassword) {
        if (this.newPassword.length < 6 && this.confirmNewPassword.length < 6) {
          this.passwordValidationHint = 'Sua senha deve conter no mínimo 6 caracteres.'
        } else if (this.newPassword !== this.confirmNewPassword) {
          this.passwordValidationHint = 'Nova senha e confirmação de senha devem ser iguais.'
        } else {
          this.passwordValidationHint = ''
        }
      }
    },
    close () {
      this.cleanFields()
      this.$emit('emit-close-modal')
    },
    cleanFields () {
      this.$refs.inputConfirmPassword.resetValidation()
      this.oldPassword = ''
      this.newPassword = ''
      this.confirmNewPassword = ''
    },
    focusInput (element) {
      if (element === 'newPassword') {
        setTimeout(() => {
          this.$refs.newPassword.$el.childNodes[0].childNodes[0].childNodes[1].childNodes[1].focus()
        })
      } else {
        setTimeout(() => {
          this.$refs.inputConfirmPassword.$el.childNodes[0].childNodes[0].childNodes[1].childNodes[1].focus()
        })
      }
    }
  }
}
