import areasApi from '@/api/areas'

export const SET_AREAS = 'SET_AREAS'
export const SET_AREA_FORMATIONS = 'SET_AREA_FORMATIONS'
export const CLEAR_AREA_FORMATIONS = 'CLEAR_AREA_FORMATIONS'
export const CLEAR_AREAS = 'CLEAR_AREAS'

const state = {
  areas: [],
  areaFormations: []
}

const getters = {
  getterAreas: (state) => state.areas,
  getterAreaFormations: (state) => state.areaFormations
}

const mutations = {
  [SET_AREAS] (state, value) {
    if (value) {
      state.areas = value
    } else {
      state.areas = []
    }
  },
  [CLEAR_AREAS] (state) {
    state.areas = []
  },
  [SET_AREA_FORMATIONS] (state, value) {
    if (value) {
      value.forEach((item) => {
        item.uniqueKey = `${item.id} ${item.area}`

        item.subAreas.forEach((subArea) => {
          subArea.uniqueKey = `${item.id} ${subArea.formationId} ${subArea.area}`
        })
      })

      state.areaFormations = value
    } else {
      state.areaFormations = []
    }
  },
  [CLEAR_AREA_FORMATIONS] (state) {
    state.areaFormations = []
  }
}

const actions = {
  actionGetAreas ({ commit }, params) {
    return areasApi.getAreas()
      .then((response) => {
        commit(SET_AREAS, response.data)
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e.response.data)
      })
  },
  actionGetFormationsAndSubAreas ({ commit }, id) {
    return areasApi.getFormationsAndSubAreas(id)
      .then((response) => {
        commit(SET_AREA_FORMATIONS, response.data)
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e.response.data)
      })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
