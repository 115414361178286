export default {
  encode (str) {
    if (str === null) {
      return null
    }
    const buf = []
    for (let i = str.length - 1; i >= 0; i--) {
      buf.unshift(['&#', str[i].charCodeAt(), ';'].join(''))
    }
    return buf.join('')
  },
  decode (str) {
    if (str === null) {
      return null
    }
    return str.replace(/&#(\d+);/g, function (match, dec) {
      return String.fromCharCode(dec)
    })
  },
  removeTagsHtml (str) {
    return str.replace(/(<([^>]+)>)/ig, '').replace(/&nbsp;/g, '')
  },
  converterAccents (str) {
    const el = document.createElement('textarea')
    el.innerHTML = str
    return el.value
  }
}
