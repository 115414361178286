import { mapActions } from 'vuex'
import { ADD_TOAST_MESSAGE } from 'vuex-toast'

export default {
  props: [
    'isOpenModal'
  ],
  data () {
    return {
      open: false,
      limitUas: null,
      isLoading: false
    }
  },
  methods: {
    ...mapActions([
      'actionUpdateUasLimitOfAllDisciplines'
    ]),
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    updateUasLimitOfAllDiscipline () {
      this.isLoading = true

      this.actionUpdateUasLimitOfAllDisciplines(this.limitUas)
        .then((response) => {
          this.isLoading = false
          this.$emit('updated-disciplines', this.limitUas)
          this.$emit('close-modal')
          this.limitUas = null

          this.addToast({
            text: response.message,
            type: 'success'
          })
        }).catch(() => {
          this.isLoading = false
          this.$emit('close-modal')
        })
    }
  }
}
