import { mapActions, mapGetters } from 'vuex'
import statusHelper from '@/helpers/status.js'
import draggable from 'vuedraggable'
import modalStructure from '@/components/modals/modal-structure'
import modalDuplicateDiscipline from '@/components/modals/duplicate-disciplines'
import modalClassroom from '@/components/modals/google-classroom-courses'
import { ADD_TOAST_MESSAGE } from 'vuex-toast'
import { findIndex } from 'lodash'
import htmlHelper from '@/helpers/html'

export default {
  data () {
    return {
      page: 1,
      searchedDisciplines: '',
      isLoadingDisciplines: false,
      isLoadingUas: false,
      isLoadingTimeline: false,
      disciplines: [],
      indexExpansionPanel: [],
      totalPages: null,
      uas: [],
      disciplineToReopen: {},
      reopenDisciplineModalSettings: {
        isOpen: false,
        icon: '',
        title: '',
        message: '',
        color: '',
        isPersistent: false,
        isConfirmLoading: false,
        textCancel: '',
        textConfirm: ''
      },
      openModalDuplicateDiscipline: false,
      disciplineId: null,
      gcCourses: [],
      loadingGcCourses: false,
      openMoldaClassroom: false,
      classroomDiscipline: {},
      googleAuthToken: null,
      googleTokenClient: null,
      isLoadingGCIntegration: false
    }
  },
  components: {
    draggable,
    modalStructure,
    modalDuplicateDiscipline,
    modalClassroom
  },
  watch: {
    getterSearchDiscipline () {
      this.page = 1

      this.searchedDisciplines = this.getterSearchDiscipline

      this.getDisciplines()
    }
  },
  mounted () {
    this.getDisciplines()
    this.initGApi()
    this.initGIS()
  },
  computed: {
    ...mapGetters([
      'getterAccount',
      'getterSearchDiscipline',
      'getterUseTimeline',
      'getterAccount'
    ]),
    altconsumer () {
      return this.getterAccount && this.getterAccount.hasOwnProperty('altconsumer') ? this.getterAccount.altconsumer : ''
    },
    isGaiaLite () {
      return this.getterAccount && this.getterAccount.clientGaia
    }
  },
  methods: {
    ...mapActions([
      'actionGetApprovedDisciplines',
      'actionGetUasByDisciplineId',
      'actionOrderUasOfDisciplines',
      'actionPutReopenDiscipline',
      'actionRedirectToSagahCm',
      'actionRedirectTeacherToSagahCm',
      'actionUpdateTimeline',
      'actionUpdateTimelineV2'
    ]),
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    getGAuthToken () {
      return new Promise((resolve, reject) => {
        this.googleTokenClient.callback = (resp) => {
          if (resp.error !== undefined) {
            reject(resp)
          }
          this.googleAuthToken = resp.access_token
          resolve(resp)
        }
        this.googleTokenClient.requestAccessToken()
      })
    },
    async getGCCourses () {
      try {
        await this.getGAuthToken()
        this.loadingGcCourses = true
        let nextPage
        this.gcCourses = []
        const params = {
          pageSize: 0,
          courseStates: 'ACTIVE'
        }

        if (this.getterAccount.role !== 'AD') {
          params.teacherId = 'me'
        }

        this.openMoldaClassroom = true
        do {
          if (nextPage) {
            params.pageToken = nextPage
          }

          const response = await window.gapi.client.request({
            path: 'https://classroom.googleapis.com/v1/courses',
            params
          })

          const responseData = response && response.result && response.result.courses && response.result.courses.length ? response.result.courses : []
          nextPage = response.result.nextPageToken || false

          this.gcCourses = [...this.gcCourses, ...responseData]
        } while (nextPage)
        this.loadingGcCourses = false
      } catch (e) {
        if (e.error === 'access_denied') {
          throw new Error('Na tela de autenticação, você tem que autorizar o acesso ao seu Google Classroom')
        }

        throw Error(`Erro na consulta de cursos do Google Classroom. Status: ${e}.`)
      }
    },
    initGIS () {
      this.googleTokenClient = window.google.accounts.oauth2.initTokenClient({
        client_id: this.getterUseTimeline.lmstoken,
        scope: 'profile https://www.googleapis.com/auth/classroom.courses.readonly https://www.googleapis.com/auth/classroom.topics https://www.googleapis.com/auth/classroom.topics.readonly https://www.googleapis.com/auth/classroom.coursework.students https://www.googleapis.com/auth/classroom.coursework.students.readonly https://www.googleapis.com/auth/classroom.coursework.me.readonly https://www.googleapis.com/auth/classroom.coursework.students https://www.googleapis.com/auth/classroom.coursework.me',
        callback: null
      })
    },
    initGApi () {
      window.gapi.load('client', function () {
        window.gapi.client.init({})
      })
    },
    getDisciplines (resetExpansions = false) {
      this.goToTop()

      if (resetExpansions) {
        this.indexExpansionPanel = []
      }

      const params = {
        page: this.page,
        search: this.getterSearchDiscipline,
        rowsPerPage: 20,
        sortDirection: ''
      }

      this.isLoadingDisciplines = true

      this.actionGetApprovedDisciplines(params)
        .then((result) => {
          this.isLoadingDisciplines = false

          result.disciplines.forEach((item) => {
            this.$set(item, 'isLoadingUas', false)
            this.$set(item, 'isLoadingOrdenate', false)
            this.$set(item, 'isLoadingTimeline', false)
          })

          this.disciplines = result.disciplines
          this.totalPages = Math.ceil(result.total / 20)
        }).catch(() => {
          this.isLoadingDisciplines = false
        })
    },
    getUasByDisciplineId (discipline, index) {
      this.disciplines[index].isLoadingUas = true

      this.actionGetUasByDisciplineId(discipline.disciplineid)
        .then((result) => {
          this.disciplines[index].isLoadingUas = false
          this.$set(this.disciplines[index], 'uas', result)
          this.scrollIntoView(discipline.disciplineid)
        }).catch(() => {
          this.disciplines[index].isLoadingUas = false
        })
    },
    getIcon (status) {
      return statusHelper.getIconStatus(status)
    },
    getColor (status) {
      return statusHelper.getColorStatus(status)
    },
    getNameIcon (status) {
      return statusHelper.getTextStatus(status)
    },
    goToTop () {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      })
    },
    scrollIntoView (id) {
      const element = document.getElementById(id)
      element.scrollIntoView({ behavior: 'smooth' })
    },
    changeOrder (discipline, index) {
      discipline.uas.forEach((item, index) => {
        item.position = index
      })

      const params = {
        disciplineid: discipline.disciplineid,
        uas: discipline.uas
      }

      this.disciplines[index].isLoadingOrdenate = true

      this.actionOrderUasOfDisciplines(params)
        .then(() => {
          this.disciplines[index].isLoadingOrdenate = false
        }).catch(() => {
          this.disciplines[index].isLoadingOrdenate = false
          this.addToast({
            text: 'Houve um erro ao ordenar as unidades, por favor tente novamente mais tarde.',
            type: 'danger'
          })
        })
    },
    openModalReopenDiscipline (discipline) {
      this.reopenDisciplineModalSettings = {
        isOpen: true,
        icon: '',
        color: 'primary',
        isPersistent: true,
        message: `<span>Confirmar reabertura da disciplina <b>${discipline.name}</b>?</span>`,
        title: 'Confirmação de Reabertura',
        isConfirmLoading: false,
        textCancel: 'Cancelar',
        textConfirm: 'Reabrir'
      }

      this.disciplineToReopen = discipline
    },
    confirmReopenDiscipline () {
      this.reopenDisciplineModalSettings.isConfirmLoading = true

      const params = {
        disciplineId: this.disciplineToReopen.disciplineid
      }

      this.actionPutReopenDiscipline(params)
        .then((result) => {
          this.addToast({
            text: 'Reabertura feita com sucesso!',
            type: 'success'
          })

          const index = findIndex(this.disciplines, (discipline) => discipline.disciplineid === result.id)

          if (index > -1) {
            this.disciplines.splice(index, 1)
          }

          this.reopenDisciplineModalSettings.isConfirmLoading = false
          this.reopenDisciplineModalSettings.isOpen = false
          this.disciplineToDelete = {}
        }).catch((error) => {
          this.addToast({
            text: `Houve um erro ao reabrir a disciplina, ${error}`,
            type: 'danger'
          })

          this.reopenDisciplineModalSettings.isConfirmLoading = false
          this.reopenDisciplineModalSettings.isOpen = false
        })
    },
    duplicateDiscipline (disciplineId) {
      this.openModalDuplicateDiscipline = true
      this.disciplineId = disciplineId
    },
    redirectToSagahCm (ua) {
      if (ua.uauserid) {
        const params = {
          uaId: ua.uaid,
          version: ua.version,
          uaIdentifier: ua.uaidentifier,
          uaUserId: ua.uauserid,
          marketplacecompositeobjecstid: ua.objectid,
          usuarioId: ua.usuarioid
        }

        this.actionRedirectTeacherToSagahCm(params)
          .then((result) => {
            window.open(result.url, '_blank')
          }).catch((e) => {
            this.addToast({
              text: e.data.message,
              type: 'danger'
            })
          })
      } else {
        const params = {
          uaId: ua.uaid
        }

        this.actionRedirectToSagahCm(params)
          .then((result) => {
            window.open(result.url, '_blank')
          }).catch((e) => {
            this.addToast({
              text: e.data.message,
              type: 'danger'
            })
          })
      }
    },
    async updateTimelineGC (discipline, index) {
      this.gcCourses = []
      this.disciplines[index].isLoadingTimeline = true
      this.classroomDiscipline = this.getTimelineParams(discipline)

      const uaNotConverted = this.classroomDiscipline.objects.find(x => x.objectid === null)

      if (uaNotConverted) {
        this.addToast({
          text: `A unidade ${uaNotConverted.title} não foi convertida para o a nova versão.`,
          type: 'danger'
        })
        this.disciplines[index].isLoadingTimeline = false
      } else {
        this.classroomDiscipline.index = index

        this.getGCCourses().then().catch(e => {
          const message = e.error && e.error.message ? e.error.message : e
          this.addToast({
            text: `Houve um erro ao atualizar a timeline: ${message}.`,
            type: 'danger'
          })
          this.cancelGcIntegration(this.classroomDiscipline)
        })
      }
    },
    cancelGcIntegration (params) {
      this.openMoldaClassroom = false
      this.disciplines[params.index].isLoadingTimeline = false
    },
    startGcIntegration (params) {
      this.isLoadingGCIntegration = true
      this.openMoldaClassroom = false
      delete params.index
      params.googleAuthToken = this.googleAuthToken
      params.lmsCode = 'GC'

      this.actionUpdateTimelineV2(params)
        .then((result) => {
          this.addToast({
            text: 'Timeline atualizada com sucesso.',
            type: 'success'
          })
        })
        .catch((e) => {
          const message = e.error && e.error.message ? e.error.message : e
          this.addToast({
            text: `Houve um erro ao atualizar a timeline: ${message}.`,
            type: 'danger'
          })
        })
        .finally(() => {
          this.isLoadingGCIntegration = false
        })
    },
    getTimelineParams (discipline) {
      const params = {
        disciplineid: discipline.disciplineid,
        disciplinename: discipline.name,
        lmsCode: this.getterUseTimeline.code,
        ies: {
          iesalias: this.getterAccount.clientGaia.iesalias,
          iesid: this.getterAccount.clientGaia.iesid,
          tenantalias: this.getterAccount.clientGaia.tenantalias,
          tenantid: this.getterAccount.clientGaia.tenantid
        },
        objects: []
      }

      params.objects = discipline.uas.map((ua, index) => {
        return {
          objectid: ua.objectid,
          uaid: ua.uaid,
          title: ua.uauserid ? htmlHelper.removeTagsHtml(ua.uateachername) : htmlHelper.decode(ua.name),
          ordem: index,
          user: ua.uauserid
            ? {
              version: ua.version,
              userid: ua.userid,
              clienteid: ua.clienteid
            }
            : null
        }
      })

      return params
    },
    updateTimelineBB (discipline, index) {
      const params = this.getTimelineParams(discipline)

      const uaNotConverted = params.objects.find(x => x.objectid === null)

      if (uaNotConverted) {
        this.addToast({
          text: `A unidade ${uaNotConverted.title} não foi convertida para o a nova versão.`,
          type: 'danger'
        })
      } else {
        this.disciplines[index].isLoadingTimeline = true
        this.actionUpdateTimelineV2(params)
          .then((result) => {
            this.addToast({
              text: 'Timeline atualizada com sucesso.',
              type: 'success'
            })
          })
          .catch((e) => {
            const message = e.error && e.error.message ? e.error.message : e
            this.addToast({
              text: `Houve um erro ao atualizar a timeline: ${message}.`,
              type: 'danger'
            })
          })
          .finally(() => {
            this.disciplines[index].isLoadingTimeline = false
          })
      }
    },
    removeTagsHtml (str) {
      return htmlHelper.removeTagsHtml(str)
    },
    decodeUtf (str) {
      return htmlHelper.decode(str)
    }
  }
}
