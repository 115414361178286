import Const from '@/helpers/const'
import { custAxios } from './genericApi'

export default {
  getCoordinators (params) {
    return custAxios().get(`${Const.API_COORDINATORS}?search=${params.search}&sortDirection=${params.sort}&page=${params.page}&rowsPerPage=${params.rowsPerPage}`)
  },
  insertCoordinator ({ name, email, disciplines }) {
    return custAxios().post(`${Const.API_COORDINATORS}`, { name, email, disciplines })
  },
  editCoordinator (params) {
    return custAxios().get(`${Const.API_COORDINATORS}/${params.id}`)
  },
  updateCoordinator (params) {
    return custAxios().put(`${Const.API_COORDINATORS}/${params.id}`, { name: params.name, email: params.email, disciplines: params.disciplines })
  },
  deleteCoordinator (id) {
    return custAxios().delete(`${Const.API_COORDINATORS}/${id}`)
  }
}
