import Const from '@/helpers/const'
import axios from 'axios'

export default {
  verifyUseTimeline (params) {
    return axios.get(`${Const.API_USE_TIMELINE}?iesid=${params.iesid}&tenantid=${params.tenantid}`)
  },
  updateTimeline (discipline) {
    return axios.post(`${Const.API_UPDATE_TIMELINE}`, discipline, {
      headers: {
        'x-api-key': Const.API_KEY_TIMELINE()
      }
    })
  },
  updateTimelineV2 (discipline) {
    return axios.post(`${Const.API_UPDATE_TIMELINE_V2}`, discipline)
  }
}
