import itemsListSelect from '@/components/items-list-select'
import modalStructure from '@/components/modals/modal-structure'

export default {
  components: {
    itemsListSelect,
    modalStructure,
    selectedItem: {}
  },
  props: [
    'openModal',
    'courses',
    'loading',
    'discipline'
  ],
  data () {
    return {
      search: '',
      confirmModalSettings: {
        isOpen: false,
        icon: '',
        title: '',
        message: '',
        color: '',
        isPersistent: false,
        isConfirmLoading: false,
        textCancel: '',
        textConfirm: ''
      }
    }
  },
  methods: {
    cancel () {
      this.$emit('emit-cancel', this.discipline)
    },
    selectCourse (item) {
      this.selectedItem = item
      this.confirmModalSettings = {
        isOpen: true,
        icon: '',
        color: 'primary',
        isPersistent: true,
        message: `<span>Confirmar integração da disciplina <b>${this.discipline.disciplinename}</b> com o curso <b>${item.name}</b>?</span>`,
        title: 'Confirmação de integração',
        isConfirmLoading: false,
        textCancel: 'Cancelar',
        textConfirm: 'Confirmar'
      }
    },
    confirmCourse () {
      this.confirmModalSettings.isOpen = false
      this.discipline.googleCourseId = this.selectedItem.id
      this.$emit('emit-confirm', this.discipline)
    }
  },
  computed: {
    headers () {
      return [
        { text: 'Código', value: 'enrollmentCode' },
        { text: 'Nome', value: 'name' }
      ]
    }
  }
}
