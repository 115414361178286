import { mapActions } from 'vuex'
import { ADD_TOAST_MESSAGE } from 'vuex-toast'

export default {
  props: [
    'listUser',
    'openModal'
  ],
  data () {
    return {
      userSelectedRecovery: '',
      isLoading: false
    }
  },
  mounted () {
    this.cleanFieldsRecoveryPassword()
  },
  methods: {
    ...mapActions([
      'actionResetPassword'
    ]),
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    recoveryPassword () {
      const params = {
        email: this.listUser[0].email,
        userid: this.userSelectedRecovery
      }

      this.isLoading = true

      this.actionResetPassword(params)
        .then(() => {
          this.addToast({
            text: 'Em breve uma nova senha será enviada em seu e-mail cadastrado.',
            type: 'success'
          })

          this.isLoading = false

          this.cleanFieldsRecoveryPassword()

          this.$emit('closedModal')
        })
    },
    cleanFieldsRecoveryPassword () {
      this.userSelectedRecovery = ''
    },
    closeModal () {
      this.cleanFieldsRecoveryPassword()
      this.$emit('closedModal')
    }
  }
}
