import { ADD_TOAST_MESSAGE } from 'vuex-toast'
import SagahFooter from '@/components/footer/'
import SagahHeader from '@/components/header/'
import ModalChangePassword from '@/components/modals/change-password/'
import SuggestContent from '@/components/modals/suggest-content'
import MenuAreas from '@/components/navigation-drawer/'
import ModalStructure from '@/components/modals/modal-structure'
import AddUaDiscipline from '@/components/modals/add-ua-discipline'
import MigrateSafeA from '@/components/modals/migrate-safea'
import TextHighlight from 'vue-text-highlight'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import htmlHelper from '../../../helpers/html'

export default {
  data () {
    return {
      buttons: [
        { text: 'Sugerir Conteúdo' },
        { text: 'Minhas Disciplinas' }
      ],
      selectionItems: [
        { text: 'Últimas adicionadas', value: 1 },
        { text: 'Mais pedidas', value: 2 },
        { text: 'Ordem alfabética', value: 3 }
      ],
      selectedOrderItem: '',
      data: {
        uas: [],
        total: 0
      },
      page: 1,
      totalPages: null,
      isLoading: false,
      menuData: [],
      totalUas: null,
      search: '',
      searchedWord: '',
      isSearching: false,
      suggestContentModal: false,
      changePasswordModal: false,
      modalAddUaDisciplineSettings: {
        show: false,
        uaId: null,
        uaUserId: null,
        uaName: null,
        uaAreaGeral: null,
        uaAreaFormation: null,
        uaSubArea: null
      }
    }
  },
  components: {
    SuggestContent,
    SagahHeader,
    MenuAreas,
    SagahFooter,
    ModalChangePassword,
    TextHighlight,
    ModalStructure,
    AddUaDiscipline,
    MigrateSafeA
  },
  mounted () {
    this.selectedOrderItem = this.selectionItems[0].value

    this.getUas(true)

    this.changePasswordModal = this.getterAccount.firstLogin
  },
  computed: {
    ...mapGetters([
      'getterAccount',
      'getterDynamicUa'
    ])
  },
  methods: {
    ...mapActions([
      'actionGetUas',
      'actionGetUasByName',
      'actionGenerateUrlUaSagah'
    ]),
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    getUas (firstCall) {
      if (this.selectionItems.length === 4) {
        this.selectionItems.pop()

        if (this.selectedOrderItem === 0) {
          this.selectedOrderItem = this.selectionItems[0].value
        }
      }

      const params = {
        sortBy: this.selectedOrderItem,
        page: this.page,
        rowsPerPage: 20,
        data: this.menuData
      }

      this.isLoading = true
      this.isSearching = false
      this.search = ''

      this.actionGetUas(params)
        .then(result => {
          this.data = result

          if (result.total === 0) {
            this.page = 0
          }

          if (firstCall) {
            this.totalUas = result.total
          }

          this.totalPages = Math.ceil(result.total / 20)
          this.isLoading = false
          this.searchedWord = ''
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    getUasByName (resetPages = false) {
      if (resetPages) {
        this.page = 1
      }

      if (this.selectionItems.length === 3 && this.search) {
        this.selectionItems.push({
          text: 'Relevância',
          value: 0
        })

        this.selectedOrderItem = this.selectionItems[3].value
      }

      this.$refs.menuAreas.reset()

      const params = {
        search: this.search,
        sortBy: this.selectedOrderItem,
        page: this.page,
        rowsPerPage: 20
      }

      this.isLoading = true
      this.isSearching = true

      this.actionGetUasByName(params)
        .then(result => {
          this.data = result

          if (result.total === 0) {
            this.page = 0
          }

          this.totalPages = Math.ceil(result.total / 20)
          this.isLoading = false
          const matches = this.search.match(/"(.*?)"/)
          this.searchedWord = matches ? matches[1] : this.search.replace(/(^"|"$)/g, '').split(' ')
          this.search = matches ? matches[0] : this.search
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    selectedMenuItems (data) {
      this.menuData = data
      this.page = 1
      this.getUas()
    },
    getDate (date) {
      return moment().diff(date, 'days')
    },
    changeOrder () {
      this.page = 1

      if (this.isSearching) {
        this.getUasByName()
      } else {
        this.getUas()
      }
    },
    changePagination () {
      if (this.isSearching) {
        this.getUasByName()
      } else {
        this.getUas()
      }
    },
    redirect (route) {
      this.$router.push({ name: route })
    },
    openUA (uaId) {
      const params = {
        uaId
      }

      this.actionGenerateUrlUaSagah(params)
        .then((result) => {
          window.open(result.url, '_blank')
        })
        .catch((e) => {
          this.addToast({
            text: e.data.message,
            type: 'danger'
          })
        })
    },
    addUaDiscipline (uaId, uaName, areaGeral, areaFormation, subArea) {
      this.modalAddUaDisciplineSettings = {
        uaId,
        uaUserId: null,
        uaName,
        uaAreaGeral: areaGeral,
        uaAreaFormation: areaFormation,
        uaSubArea: subArea,
        show: true
      }
    },
    closeSuggestContentModal () {
      this.suggestContentModal = false
    },
    closeChangePasswordModal () {
      this.changePasswordModal = false
    },
    changePassword (changePasswordModal) {
      this.changePasswordModal = changePasswordModal
    },
    formatObjectiveUa (name) {
      return htmlHelper.converterAccents(name)
    },
    decodeUtf (name) {
      return htmlHelper.decode(name)
    },
    clearFilter () {
      this.search = ''

      if (this.isSearching) {
        this.getUasByName(true)
        this.isSearching = false
      }
    },
    iconPrivateArea (area) {
      return require(`@/assets/imgs/${area.toLowerCase()}.svg`)
    }
  }
}
