import { ADD_TOAST_MESSAGE } from 'vuex-toast'
import { mapActions } from 'vuex'
import simpleItemsList from '@/components/simple-items-list'
import itemsList from '@/components/items-list'
import modalStructure from '@/components/modals/modal-structure'
import disciplineModalStructure from '@/components/modals/discipline-search'
import { isEqual, cloneDeep, findIndex } from 'lodash'

export default {
  components: {
    simpleItemsList,
    itemsList,
    modalStructure,
    disciplineModalStructure
  },
  data () {
    return {
      useCoordinatorsFlow: null,
      inputCoordinatorName: '',
      inputCoordinatorEmail: '',
      tableCoordinatorDisciplines: [],
      coordinators: [],
      page: 1,
      totalPages: 0,
      coordinatorInitialState: {
        id: '',
        name: '',
        disciplines: []
      },
      selectedCoordinator: {
        id: '',
        name: '',
        disciplines: []
      },
      isLoadingUpdate: false,
      isLoadingCoordinators: false,
      itemToDelete: null,
      coordinatorToReactivate: {},
      alertModalSettings: {
        isOpen: false,
        icon: '',
        title: '',
        message: '',
        color: '',
        isPersistent: false,
        isConfirmLoading: false,
        textCancel: '',
        textConfirm: ''
      },
      disciplineToDelete: null,
      disciplineModalSettings: {
        isOpen: false,
        icon: '',
        title: ''
      },
      disciplineAlertModalSettings: {
        isOpen: false,
        icon: '',
        title: '',
        message: '',
        color: '',
        isPersistent: false,
        isConfirmLoading: false,
        textCancel: '',
        textConfirm: ''
      },
      coordinatorReactivateModalSettings: {
        isOpen: false,
        icon: '',
        title: '',
        message: '',
        color: '',
        isPersistent: false,
        isConfirmLoading: false,
        textCancel: '',
        textConfirm: ''
      },
      search: '',
      sortType: 'asc',
      sortListTypes: [
        { name: 'Ordem alfabética crescente', value: 'asc' },
        { name: 'Ordem alfabética decrescente', value: 'desc' }
      ]
    }
  },
  mounted () {
    this.isLoadingCoordinators = true

    this.getCoordinators()

    this.getFlow()
  },
  methods: {
    ...mapActions([
      'actionGetCoordinators',
      'actionEditCoordinator',
      'actionInsertCoordinator',
      'actionUpdateCoordinator',
      'actionDeleteCoordinator',
      'actionGetCoordinatorsFlow',
      'actionUpdateCoordinatorsFlow',
      'actionReactivateUser'
    ]),
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    getFlow () {
      this.actionGetCoordinatorsFlow()
        .then(response => {
          this.useCoordinatorsFlow = !response.coordinates
        })
        .catch(message => {
          this.addToast({
            text: message,
            type: 'danger'
          })
        })
    },
    updateFlow () {
      this.actionUpdateCoordinatorsFlow(!this.useCoordinatorsFlow)
        .then(response => {
          this.addToast({
            text: response.message,
            type: 'success'
          })
        })
        .catch(message => {
          this.addToast({
            text: message,
            type: 'danger'
          })
        })
    },
    editCoordinator (item) {
      this.actionEditCoordinator({ id: item.id })
        .then(response => {
          Object.assign(this.selectedCoordinator, response)
          this.coordinatorInitialState = cloneDeep(response)
        })
        .catch(message => {
          this.addToast({
            text: message,
            type: 'danger'
          })
        })
    },
    insertCoordinator (item) {
      if (!item.name || !item.email) {
        return
      }

      this.isLoadingUpdate = true
      const { name, email, disciplines } = item
      this.actionInsertCoordinator({ name, email, disciplines })
        .then(response => {
          this.coordinators.push(response)

          this.addToast({
            text: 'Coordenador adicionado com sucesso!',
            type: 'success'
          })

          this.clearFields()
        })
        .catch(message => {
          this.addToast({
            text: message,
            type: 'danger'
          })
        })
        .finally(() => {
          this.isLoadingUpdate = false
        })
    },
    updateCoordinator () {
      if (!this.selectedCoordinator.name || !this.selectedCoordinator.email) {
        return
      }

      this.isLoadingUpdate = true
      this.actionUpdateCoordinator(this.selectedCoordinator)
        .then(() => {
          const coordinatorToReplace = this.coordinators.find(
            coordinator => coordinator.id === this.selectedCoordinator.id
          )

          Object.assign(coordinatorToReplace, this.selectedCoordinator)

          this.addToast({
            text: 'Coordenador atualizado com sucesso!',
            type: 'success'
          })

          this.selectedCoordinator = {
            id: '',
            name: ''
          }

          this.clearFields()
        })
        .catch(message => {
          this.addToast({
            text: message,
            type: 'danger'
          })
        })
        .finally(() => {
          this.isLoadingUpdate = false
        })
    },
    deleteItem (id) {
      this.alertModalSettings.isConfirmLoading = true
      this.actionDeleteCoordinator(id)
        .then(response => {
          this.alertModalSettings.isOpen = false

          if (response.message === 'O Coordenador foi desativado por conter Unidades personalizadas.') {
            const index = findIndex(this.coordinators, (coordinator) => coordinator.id === id)

            if (index > -1) {
              this.coordinators[index].active = false
            }
          } else {
            this.coordinators = this.coordinators.filter((coordinator) => coordinator.id !== id)
          }

          this.addToast({
            text: response.message,
            type: 'success'
          })

          if (id === this.selectedCoordinator.id) {
            this.clearFields()
          }
        })
        .catch(message => {
          this.addToast({
            text: message,
            type: 'danger'
          })
        })
        .finally(() => {
          this.alertModalSettings.isConfirmLoading = false
        })
    },
    deleteDisciplineItem (id) {
      this.selectedCoordinator.disciplines = this.selectedCoordinator.disciplines.filter(
        disc => disc.id !== id
      )
      this.closeAlertDisciplineModal()
    },
    getCoordinators () {
      this.isLoadingCoordinators = true

      const params = {
        search: this.search || '',
        sort: this.sortType,
        page: this.page,
        rowsPerPage: 20
      }

      this.actionGetCoordinators(params)
        .then(response => {
          this.coordinators = response.coordinators

          this.totalPages = Math.ceil(response.total / 20)
        })
        .catch(message => {
          this.addToast({
            text: message,
            type: 'danger'
          })
        })
        .finally(() => {
          this.isLoadingCoordinators = false
        })
    },
    filterItems (query) {
      this.page = 1
      this.search = query
      this.getCoordinators()
    },
    sortItems (type) {
      this.page = 1
      this.sortType = type
      this.getCoordinators()
    },
    changePagination (page) {
      this.page = page
      this.getCoordinators()
    },
    clearFields () {
      this.selectedCoordinator = {
        id: '',
        name: '',
        disciplines: []
      }
      this.inputCoordinatorEmail = ''
      this.inputCoordinatorName = ''
      this.tableCoordinatorDisciplines = []
    },
    openConfirmModalDiscipline (item) {
      this.disciplineToDelete = item.id
      this.disciplineAlertModalSettings = {
        isOpen: true,
        icon: '',
        color: 'primary',
        isPersistent: true,
        message: `<span>Confirma a exclusão da Disciplina do Coordenador: <b>${item.name}</b></span>`,
        title: 'Confirmação de Exclusão',
        isConfirmLoading: false,
        textCancel: 'Cancelar',
        textConfirm: 'Excluir'
      }
    },
    openConfirmModal (item) {
      this.itemToDelete = item.id
      this.alertModalSettings = {
        isOpen: true,
        icon: '',
        color: 'primary',
        isPersistent: true,
        message: `<span>Confirma a exclusão do Coordenador: <b>${item.name}</b></span>`,
        title: 'Confirmação de Exclusão',
        isConfirmLoading: false,
        textCancel: 'Cancelar',
        textConfirm: 'Excluir'
      }
    },
    openAddDisciplineModal () {
      this.disciplineModalSettings = {
        isOpen: true,
        icon: '',
        title: 'Selecione uma disciplina'
      }
    },
    selectedDiscipline ({ disciplineid, name }) {
      const existsDiscipline = this.selectedCoordinator.disciplines.find(
        x => x.id === disciplineid
      )
      if (!existsDiscipline) {
        this.selectedCoordinator.disciplines.push({
          id: disciplineid,
          name
        })
      }
    },
    closeAlertDisciplineModal () {
      this.disciplineAlertModalSettings.isOpen = false
      this.disciplineAlertModalSettings.isConfirmLoading = false
    },
    closeDisciplineModal () {
      this.saveChangesReminder()
      this.disciplineModalSettings.isOpen = false
      this.disciplineModalSettings.isConfirmLoading = false
    },
    closeAlertModal () {
      this.alertModalSettings.isOpen = false
      this.alertModalSettings.isConfirmLoading = false
    },
    saveChangesReminder () {
      if (!isEqual(this.selectedCoordinator, this.coordinatorInitialState)) {
        this.addToast({
          text: 'Não se esqueça de salvar as alterações!',
          type: 'warning'
        })
      }
    },
    reactiveAccount (user) {
      this.coordinatorToReactivate = user

      this.coordinatorReactivateModalSettings = {
        isOpen: true,
        icon: '',
        title: 'Reativar Coordenador',
        message: `<span>Deseja reativar o Coordenador <b> ${this.coordinatorToReactivate.name}</b>?</span>`,
        color: 'primary',
        isPersistent: true,
        isConfirmLoading: false,
        textCancel: 'Cancelar',
        textConfirm: 'Reativar'
      }
    },
    closeModalReactivateCoordinator () {
      this.coordinatorToReactivate = {}

      this.coordinatorReactivateModalSettings = {
        isOpen: false,
        icon: '',
        title: '',
        message: '',
        color: '',
        isPersistent: false,
        isConfirmLoading: false,
        textCancel: '',
        textConfirm: ''
      }
    },
    confirmReactivateCoordinator () {
      this.coordinatorReactivateModalSettings.isConfirmLoading = true

      this.actionReactivateUser(this.coordinatorToReactivate.id)
        .then((result) => {
          const index = findIndex(this.coordinators, (coordinator) => coordinator.id === this.coordinatorToReactivate.id)

          if (index > -1) {
            this.coordinators[index].active = true
          }

          this.addToast({
            text: result.message,
            type: 'success'
          })

          this.closeModalReactivateCoordinator()
        }).catch((err) => {
          this.coordinatorReactivateModalSettings.isConfirmLoading = false

          this.addToast({
            text: err,
            type: 'danger'
          })
        })
    }
  }
}
