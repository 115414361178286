import coursesApi from '@/api/courses'
import flowApi from '@/api/flow'

export const SET_COURSES = 'SET_COURSES'
export const SET_SELECTED_COURSE = 'SET_SELECTED_COURSE'
export const SET_FLOW = 'SET_FLOW'

const state = {
  courses: [],
  selectedCourse: {},
  flow: Boolean
}

const getters = {
  getterFlow: state => state.flow
}

const mutations = {
  [SET_COURSES] (state, values) {
    state.courses = values
  },
  [SET_SELECTED_COURSE] (state, value) {
    state.selectedCourse = value
  },
  [SET_FLOW] (state, value) {
    state.flow = value
  }
}

const actions = {
  actionGetCourseFlow ({ commit }) {
    return flowApi.getFlow()
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch(e => {
        return Promise.reject(e.data.message)
      })
  },
  actionUpdateFlow ({ commit }, status) {
    const params = {
      flow: 'courses',
      status
    }
    return flowApi.updateFlow(params)
      .then(response => {
        commit(SET_FLOW, status)
        return Promise.resolve(response.data)
      })
      .catch(e => {
        return Promise.reject(e.data.message)
      })
  },
  actionGetCourses ({ commit }, params) {
    return coursesApi.getCourses(params)
      .then((response) => {
        commit(SET_COURSES, response.data)
        return Promise.resolve(response.data)
      })
      .catch(e => {
        return Promise.reject(e.data.message)
      })
  },
  actionInsertCourse ({ commit }, name) {
    return coursesApi
      .insertCourse(name)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(e => {
        return Promise.reject(e.data.message)
      })
  },
  actionUpdateCourse ({ commit }, params) {
    return coursesApi
      .updateCourse(params)
      .then(response => {
        commit(SET_SELECTED_COURSE, response.data)
        return Promise.resolve(response.data)
      })
      .catch(e => {
        return Promise.reject(e.data.message)
      })
  },
  actionDeleteCourse ({ commit }, id) {
    return coursesApi
      .deleteCourse(id)
      .then(response => {
        return Promise.resolve(response.data)
      })
      .catch(e => {
        return Promise.reject(e.data.message)
      })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
