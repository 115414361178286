import { ADD_TOAST_MESSAGE } from 'vuex-toast'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: [
    'openModal'
  ],
  data () {
    return {
      course: '',
      discipline: '',
      unity: '',
      objetive: '',
      justification: '',
      isButtonLoading: false
    }
  },
  methods: {
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    ...mapActions([
      'actionSendSuggestion'
    ]),
    closeModal () {
      this.$emit('emit-close-modal')
      this.resetFields()
    },
    confirm () {
      this.isButtonLoading = true

      const params = {
        userid: this.userId,
        clientid: this.clientId,
        course: this.course,
        discipline: this.discipline,
        unity: this.unity,
        objetive: this.objetive,
        justification: this.justification
      }

      this.actionSendSuggestion(params)
        .then((response) => {
          this.isButtonLoading = false

          this.closeModal()

          this.addToast({
            text: response.message,
            type: 'success'
          })
        })
        .catch((error) => {
          this.addToast({
            text: error,
            type: 'danger'
          })
        })
    },
    resetFields () {
      this.$refs.form.resetValidation()

      this.course = ''
      this.discipline = ''
      this.unity = ''
      this.objetive = ''
      this.justification = ''
    }
  },
  computed: {
    ...mapGetters([
      'getterAccount'
    ]),
    clientId () {
      return this.getterAccount.clientId
    },
    userId () {
      return this.getterAccount.userId
    }
  }
}
