import { CLEAR_ACCOUNT } from './account'
import { CLEAR_CONFIG_DYNAMIC_UA, CLEAR_CONFIG_TIMELINE } from './client'
import { CLEAR_AREAS, CLEAR_AREA_FORMATIONS } from './areas'

export const cleanStorage = ({ commit }) => {
  window.localStorage.removeItem('token-sagah')

  const mutations = [
    CLEAR_ACCOUNT,
    CLEAR_AREAS,
    CLEAR_AREA_FORMATIONS,
    CLEAR_CONFIG_DYNAMIC_UA,
    CLEAR_CONFIG_TIMELINE
  ]

  mutations.map(mutation => commit(mutation))

  location.reload(true)
}
