import { ADD_TOAST_MESSAGE } from 'vuex-toast'
import { mapActions } from 'vuex'
import modalStructure from '@/components/modals/modal-structure'
import statusHelper from '../../../helpers/status'

export default {
  components: {
    modalStructure
  },
  props: [
    'visible',
    'uaId',
    'uaUserId',
    'uaName',
    'uaAreaGeral',
    'uaAreaFormation',
    'uaSubArea'
  ],
  data () {
    return {
      configUnapproved: {
        search: '',
        selectedSortType: 'asc',
        isLoadingItems: false,
        page: 1,
        totalPages: null
      },
      configDisciplineAdd: {
        search: '',
        selectedSortType: 'asc',
        isLoadingItems: false,
        page: 1,
        totalPages: null
      },
      sortTypes: [
        {
          name: 'Crescente',
          value: 'asc'
        },
        {
          name: 'Decrescente',
          value: 'desc'
        }
      ],
      disciplinesUnapproved: {
        total: 0,
        disciplines: []
      },
      disciplinesAdd: {
        total: 0,
        disciplines: []
      },
      loadingAddIndex: null,
      loadingRemoveIndex: null,
      alertModalSettings: {
        isOpen: false,
        icon: '',
        title: '',
        message: '',
        color: '',
        isPersistent: false,
        isConfirmLoading: false,
        textCancel: '',
        textConfirm: ''
      },
      selectedTab: 0
    }
  },
  computed: {
    show: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.$emit('close')
        }
        this.selectedTab = 0
      }
    }
  },
  watch: {
    uaId: function (_) {
      this.searchDisciplinesAdd()
      this.searchDisciplinesUnapproved()
    }
  },
  methods: {
    ...mapActions([
      'actionGetDisciplinesUnapproved',
      'actionPostUaInDiscipline',
      'actionGetByUa',
      'actionDeleteUaOfDiscipline'
    ]),
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    searchDisciplinesUnapproved () {
      this.configUnapproved.isLoadingItems = true

      const params = {
        search: this.configUnapproved.search ? this.configUnapproved.search : '',
        sortBy: 'name',
        sortDirection: this.configUnapproved.selectedSortType,
        page: this.configUnapproved.page,
        rowsPerPage: 50
      }

      this.actionGetDisciplinesUnapproved(params)
        .then((response) => {
          if (response.total === 0) {
            this.configUnapproved.page = 0
          }

          this.configUnapproved.totalPages = Math.ceil(response.total / params.rowsPerPage)
          this.disciplinesUnapproved = response
          this.configUnapproved.isLoadingItems = false
        }).catch(() => {
          this.configUnapproved.isLoadingItems = false
        })
    },
    changePaginationUnapproved () {
      this.searchDisciplinesUnapproved()
    },
    searchTextUnapproved () {
      this.configUnapproved.page = 1
      this.searchDisciplinesUnapproved()
    },
    sortListUnapproved (sort) {
      this.configUnapproved.selectedSortType = sort
      this.configUnapproved.page = 1
      this.searchDisciplinesUnapproved()
    },
    selectedItem (item, index) {
      this.loadingAddIndex = index

      const params = {
        disciplineId: item.disciplineid,
        data: {
          idUa: this.uaId,
          uaUserId: this.uaUserId !== null && this.uaUserId > 0 ? this.uaUserId : null,
          isTeacherUAs: this.uaUserId !== null && this.uaUserId > 0
        }
      }

      this.actionPostUaInDiscipline(params)
        .then((uaDiscipline) => {
          this.loadingAddIndex = null
          item.uastotal += 1
          this.disciplinesAdd.total += 1

          this.disciplinesAdd.disciplines.push({
            id: uaDiscipline.id,
            disciplineid: item.disciplineid,
            name: item.name,
            courseid: item.courseid,
            course: item.course,
            uausuarioid: this.uaUserId,
            status: item.status
          })

          this.addToast({
            text: 'Unidade adicionada na disciplina',
            type: 'success'
          })
        }).catch((e) => {
          this.loadingAddIndex = null

          this.addToast({
            text: e.message || e,
            type: 'danger'
          })
        })
    },
    searchDisciplinesAdd () {
      this.configDisciplineAdd.isLoadingItems = true

      const params = {
        uaId: this.uaId,
        search: this.configDisciplineAdd.search ? this.configDisciplineAdd.search : '',
        sortBy: 'name',
        sortDirection: this.configDisciplineAdd.selectedSortType,
        page: this.configDisciplineAdd.page,
        rowsPerPage: 50
      }

      this.actionGetByUa(params)
        .then((response) => {
          if (response.total === 0) {
            this.configDisciplineAdd.page = 0
          }

          this.configDisciplineAdd.totalPages = Math.ceil(response.total / params.rowsPerPage)
          this.disciplinesAdd = response
          this.configDisciplineAdd.isLoadingItems = false
        }).catch(() => {
          this.configDisciplineAdd.isLoadingItems = false
        })
    },
    changePaginationAdd  () {
      this.searchDisciplinesAdd()
    },
    searchTextAdd  () {
      this.configDisciplineAdd.page = 1
      this.searchDisciplinesAdd()
    },
    sortListAdd (sort) {
      this.configDisciplineAdd.selectedSortType = sort
      this.configDisciplineAdd.page = 1
      this.searchDisciplinesAdd()
    },
    closeDeleteUaModal () {
      this.alertModalSettings.isOpen = false
      this.alertModalSettings.isConfirmLoading = false
      this.loadingRemoveIndex = null
    },
    clearFilterMyDiscipline () {
      this.configUnapproved.search = ''
      this.searchTextUnapproved()
    },
    clearFilter () {
      this.configDisciplineAdd.search = ''
      this.searchTextAdd()
    },
    getColor (status) {
      return statusHelper.getColorStatus(status)
    },
    getIconStatus (status) {
      return statusHelper.getIconStatus(status)
    },
    getTextStatus (status) {
      return statusHelper.getTextStatus(status)
    },
    showModalDeleteUa (disicplineUa, index) {
      this.itemToDelete = {
        idDiscipline: disicplineUa.disciplineid,
        disciplineuaid: disicplineUa.id
      }

      this.loadingRemoveIndex = index

      this.alertModalSettings = {
        isOpen: true,
        icon: '',
        color: 'primary',
        isPersistent: true,
        message: `<span>Deseja realmente excluir a disciplina <b>${disicplineUa.name}</b> dessa unidade?</span>`,
        title: 'Confirmação de Exclusão',
        isConfirmLoading: false,
        textCancel: 'Cancelar',
        textConfirm: 'Excluir'
      }
    },
    deleteUaOfDiscipline () {
      this.alertModalSettings.isConfirmLoading = true

      this.actionDeleteUaOfDiscipline(this.itemToDelete)
        .then(() => {
          this.disciplinesAdd.disciplines.forEach((discipline, index) => {
            if (this.itemToDelete.disciplineuaid === discipline.id) {
              this.disciplinesAdd.disciplines.splice(index, 1)

              this.disciplinesAdd.total = this.disciplinesAdd.total - 1
            }
          })

          const indexUnnaproved = this.disciplinesUnapproved.disciplines.findIndex(
            discipline => Number(discipline.disciplineid) === Number(this.itemToDelete.idDiscipline)
          )
          if (indexUnnaproved !== -1) {
            this.disciplinesUnapproved.disciplines[indexUnnaproved].uastotal--
          }

          this.itemToDelete = {}
          this.alertModalSettings.isConfirmLoading = false
          this.alertModalSettings.isOpen = false
          this.loadingRemoveIndex = null

          this.addToast({
            text: 'Disciplina excluída da Unidade com sucesso',
            type: 'success'
          })
        }).catch((e) => {
          this.alertModalSettings.isConfirmLoading = false
          this.alertModalSettings.isOpen = false
          this.loadingRemoveIndex = null

          this.addToast({
            text: e.message || e,
            type: 'danger'
          })
        })
    }
  }
}
