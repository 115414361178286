import { mapActions } from 'vuex'
import modalStructure from '@/components/modals/modal-structure'
import AddUaDiscipline from '@/components/modals/add-ua-discipline'
import { ADD_TOAST_MESSAGE } from 'vuex-toast'
import { findIndex } from 'lodash'
import htmlHelper from '../../../helpers/html'

export default {
  components: {
    modalStructure,
    AddUaDiscipline
  },
  data () {
    return {
      page: 1,
      totalPages: 0,
      search: '',
      searchedWord: '',
      isLoading: true,
      fixedHeader: true,
      showRemoveModal: false,
      messageModal: 'Deseja realmente excluir essa unidade?',
      itemToDelete: null,
      headers: [
        {
          text: 'UNIDADE',
          value: 'name',
          align: 'left'
        },
        {
          text: 'SITUAÇÃO',
          value: 'situation',
          align: 'right'
        },
        {
          text: 'AÇÕES',
          value: 'action',
          align: 'right'
        }
      ],
      units: [],
      modalAddUaDisciplineSettings: {
        show: false,
        uaId: null,
        uaUserId: null,
        uaName: null,
        uaAreaGeral: null,
        uaAreaFormation: null,
        uaSubArea: null
      }
    }
  },

  mounted () {
    this.getUnits()
  },
  methods: {
    ...mapActions([
      'actionGetUnits',
      'actionRedirectToEditInSagahCM',
      'actionDeleteUAs'
    ]),
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    openRemoveModal (id, version, situation) {
      this.itemToDelete = {
        id,
        version
      }

      if (situation === 'exportada') {
        this.messageModal = 'Deseja realmente excluir essa unidade/Exportada?'
      } else {
        this.messageModal = 'Deseja realmente excluir essa unidade?'
      }

      this.showRemoveModal = true
    },
    cancel () {
      this.showRemoveModal = false
      this.itemToDelete = null
    },
    removeMyUnits () {
      this.isLoading = true
      this.showRemoveModal = false

      this.actionDeleteUAs(this.itemToDelete)
        .then((response) => {
          this.itemToDelete = null
          this.isLoading = false

          const index = findIndex(this.units.uas, (unit) => {
            // eslint-disable-next-line eqeqeq
            return unit.id == response.uausuarioid
          })
          if (index >= 0) {
            this.units.uas.splice(index, 1)
          }
        }).catch((err) => {
          this.showRemoveModal = false
          this.isLoading = false

          this.addToast({
            text: `Houve um erro ao deletar sua unidade. ${err}.`,
            type: 'danger'
          })
        })
    },
    redirectToSagahCM (uaId, version, uaIdentifier, uaUserId, marketplacecompositeobjecstid, usuarioId) {
      this.isLoading = true

      const params = {
        uaId,
        version,
        uaIdentifier,
        uaUserId,
        marketplacecompositeobjecstid,
        usuarioId
      }

      this.actionRedirectToEditInSagahCM(params)
        .then((result) => {
          this.isLoading = false

          window.open(
            result.url, '_blank'
          )
        }).catch((e) => {
          this.isLoading = false

          this.addToast({
            text: e.data.message,
            type: 'danger'
          })
        })
    },
    addUaDiscipline (uaId, uaName, uaUserId) {
      this.modalAddUaDisciplineSettings = {
        uaId,
        uaUserId,
        uaName,
        uaAreaGeral: null,
        uaAreaFormation: null,
        uaSubArea: null,
        show: true
      }
    },
    changePagination () {
      this.getUnits()
    },
    formater (str) {
      return htmlHelper.removeTagsHtml(str)
    },
    getUnits (resetPages = false) {
      if (resetPages) {
        this.page = 1
      }

      this.isLoading = true

      const params = {
        search: this.search,
        page: this.page
      }

      this.actionGetUnits(params)
        .then((response) => {
          this.searchedWord = this.search
          this.units = response
          this.totalPages = Math.ceil(response.total / 20)
          this.isLoading = false
        }).catch((err) => {
          this.addToast({
            text: err,
            type: 'danger'
          })

          this.isLoading = false
        })
    },
    clearFilter () {
      this.search = ''

      if (this.searchedWord) {
        this.searchedWord = ''
        this.getUnits(true)
      }
    },
    backToHome () {
      this.$router.push({ name: 'Home' })
      this.dialog = false
    }
  }
}
