import { mapActions } from 'vuex'
import { ADD_TOAST_MESSAGE } from 'vuex-toast'

export default {
  props: [
    'disciplineId',
    'isOpen'
  ],
  data () {
    return {
      disciplines: [
        { name: '', hint: '' }
      ],
      isLoading: false
    }
  },
  methods: {
    ...mapActions([
      'actionPutDuplicateDiscipline'
    ]),
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    addDiscipline () {
      this.disciplines.push({ name: '', hint: '' })

      setTimeout(() => {
        document.getElementsByClassName('duplicate-discipline-body')[0].scrollTop = document.getElementsByClassName('duplicate-discipline-body')[0].scrollHeight - document.getElementsByClassName('duplicate-discipline-body')[0].clientHeight
      })
    },
    removeDiscipline (index) {
      this.disciplines.splice(index, 1)
    },
    closeModal () {
      this.$emit('close-modal')

      this.disciplines = [
        { name: '' }
      ]
    },
    duplicateDisciplines () {
      const removeEmptyNames = this.disciplines.filter((discipline) => {
        return discipline.name !== ''
      })

      const disciplines = []

      removeEmptyNames.forEach((discipline) => {
        disciplines.push(discipline.name)
      })

      const params = {
        disciplineId: this.disciplineId,
        disciplineNames: disciplines
      }

      this.isLoading = true

      this.actionPutDuplicateDiscipline(params)
        .then(() => {
          this.isLoading = false

          this.closeModal()

          this.addToast({
            text: 'Disciplinas criadas com sucesso!',
            type: 'success'
          })
        }).catch((error) => {
          if (error && error.existedsNames) {
            error.existedsNames.forEach(item => {
              this.disciplines.forEach(discipline => {
                if (item.toLowerCase() === discipline.name.toLowerCase()) {
                  discipline.hint = 'Esta disciplina não pode ser criada pois já existe uma disciplina com este nome.'
                }
              })
            })
          } else if (error && error.message) {
            this.addToast({
              text: error.message,
              type: 'danger'
            })
          }

          this.isLoading = false
        })
    },
    validateInput (name, index) {
      const filteredArray = this.disciplines.filter((discipline) => {
        return discipline.name.toLowerCase() === name.toLowerCase()
      })

      if (filteredArray.length > 1) {
        this.disciplines[index].hint = 'Atenção, disciplina duplicada.'
      } else {
        this.disciplines[index].hint = ''
      }
    },
    validateDisabledButton () {
      const haveHint = this.disciplines.filter((d) => {
        return d.hint === 'Atenção, disciplina duplicada.'
      })

      if (haveHint.length > 0) {
        return true
      } else {
        return false
      }
    },
    validateEmptyValue () {
      if (this.disciplines.length === 1) {
        const haveEmptyInput = this.disciplines.filter((d) => {
          return d.name === ''
        })

        if (haveEmptyInput.length > 0) {
          return true
        } else {
          return false
        }
      }
    }
  }
}
