import uaApi from '@/api/ua'
import timelineApi from '@/api/timeline'

export const SET_DYNAMIC_UA = 'SET_DYNAMIC_UA'
export const SET_USE_TIMELINE = 'SET_USE_TIMELINE'
export const CLEAR_CONFIG_DYNAMIC_UA = 'CLEAR_CONFIG_DYNAMIC_UA'
export const CLEAR_CONFIG_TIMELINE = 'CLEAR_CONFIG_TIMELINE'

const state = {
  dynamicUa: false,
  useTimeline: false
}

const getters = {
  getterDynamicUa: (state) => state.dynamicUa,
  getterUseTimeline: (state) => state.useTimeline
}

const mutations = {
  [SET_DYNAMIC_UA] (state, values) {
    state.dynamicUa = values.isDynamicUA
  },
  [SET_USE_TIMELINE] (state, values) {
    state.useTimeline = values
  },
  [CLEAR_CONFIG_DYNAMIC_UA] (state) {
    state.dynamicUa = false
  },
  [CLEAR_CONFIG_TIMELINE] (state) {
    state.useTimeline = false
  }
}

const actions = {
  actionVerifyDynamicUA ({ commit }, params) {
    return uaApi.verifyDynamicUA()
      .then((response) => {
        commit(SET_DYNAMIC_UA, response.data)
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.response.data.message)
      })
  },
  actionVerifyUseTimeline ({ commit }, params) {
    return timelineApi.verifyUseTimeline(params)
      .then((response) => {
        commit(SET_USE_TIMELINE, response.data)
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.response.data.error.message)
      })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
