export default {
  props: [
    'items',
    'title',
    'textNoneFound',
    'hideEdit',
    'isLoadingItems',
    'isDisabledItems'
  ],
  data () {
    return {
      search: '',
      selectedSortType: 'asc'
    }
  },
  computed: {
    filteredItems () {
      return this.items.filter((item) => {
        return item.name.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  methods: {
    updateItem (item) {
      this.$emit('emit-update-item', item)
    },
    deleteItem (item) {
      this.$emit('emit-delete-item', item)
    },
    sortList (sort) {
      this.$emit('emit-sort-list', sort)
    }
  }
}
