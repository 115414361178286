import { mapActions, mapGetters } from 'vuex'
import { ADD_TOAST_MESSAGE } from 'vuex-toast'
import { mask } from 'vue-the-mask'

export default {
  props: [
    'openModal'
  ],
  directives: {
    mask
  },
  data () {
    return {
      isLoading: false,
      user: {
        name: '',
        email: '',
        cpf: ''
      },
      rules: {
        required: value => !!value || 'Campo obrigatório.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'E-mail inválido'
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'getterAccount'
    ])
  },
  mounted () {
    this.getUserInfo()
  },
  methods: {
    ...mapActions([
      'actionGetUserInfo',
      'actionMigration'
    ]),
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    confirmMigration () {
      const params = {
        email: this.user.email,
        cpf: this.user.cpf,
        name: this.user.name
      }

      this.isLoading = true

      this.actionMigration(params)
        .then(() => {
          this.addToast({
            text: 'Configuração realizada com sucesso.',
            type: 'success'
          })
        })
        .catch((err) => {
          this.addToast({
            text: err.data.message,
            type: 'danger'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getUserInfo () {
      this.isLoading = true

      this.actionGetUserInfo()
        .then((data) => {
          this.user = data
          this.isLoading = false
        })
    }
  }
}
