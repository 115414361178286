import { mapGetters, mapActions } from 'vuex'
import modalStructure from '@/components/modals/modal-structure'

export default {
  components: {
    modalStructure
  },
  data: () => {
    return {
      showLogoutModal: false,
      showSettingsModal: false,
      isUserLogoutMenuOpen: false
    }
  },
  computed: {
    ...mapGetters([
      'getterAccount'
    ]),
    username () {
      return this.getterAccount && this.getterAccount.hasOwnProperty('name') ? this.getterAccount.name.split(' ')[0] : ''
    }
  },
  methods: {
    ...mapActions([
      'cleanStorage'
    ]),
    openLogoutModal () {
      this.showLogoutModal = true
    },
    openChangePasswordModal () {
      this.$emit('changePassword', true)
    },
    cancel () {
      this.showLogoutModal = false
    },
    logout () {
      this.cleanStorage()
    },
    reload () {
      this.$router.go(this.$router.currentRoute)
    }
  }
}
