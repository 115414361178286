const API_HOST = () => {
  const { host } = window.location

  if (host.includes('localhost') || host.includes('hlg') || host.includes('ngrok') || host.includes('dev')) {
    return 'https://api.dev-plataforma.grupoa.education/v1/sagah/catalogo'
  } else {
    return 'https://api.plataforma.grupoa.education/v1/sagah/catalogo'
  }
}

const API_HOST_TIMELINE = () => {
  const { host } = window.location

  if (host.includes('localhost') || host.includes('hlg') || host.includes('ngrok')) {
    return 'https://dev.api.sagah.grupoa.education'
  } else {
    return 'https://api.sagah.grupoa.education'
  }
}

const API_HOST_TIMELINE_V2 = () => {
  const { host } = window.location

  if (host.includes('localhost') || host.includes('hlg') || host.includes('ngrok')) {
    return 'https://api.hlg-plataforma.grupoa.education'
  } else {
    return 'https://api.plataforma.grupoa.education'
  }
}

const API_KEY_TIMELINE = () => {
  const { host } = window.location

  if (host.includes('localhost') || host.includes('hlg') || host.includes('ngrok')) {
    return '9K6LIVepyf7uzwVqsbdNg31c0WUhouF68M7PpauZ'
  } else {
    return 'OxyxAGfDY74FR7EjXUoWA5VReuwcxmye3BNqIhgw'
  }
}

const API_URL = `${API_HOST()}/api/v1`
const API_SESSION = `${API_URL}/session`
const API_USER = `${API_URL}/user`
const API_COURSES = `${API_URL}/course`
const API_COORDINATORS = `${API_URL}/coordinator`
const API_PROFESSORS = `${API_URL}/teacher`
const API_DISCIPLINES = `${API_URL}/discipline`
const API_FLOW = `${API_URL}/flow`
const API_AREAS = `${API_URL}/area`
const API_ORGANIZATION_CHART = `${API_URL}/organizationchart`
const API_UA = `${API_URL}/ua`
const API_SUGGEST = `${API_URL}/suggest`
const API_SAGAHTEACHERTOKEN = `${API_URL}/tokenUaTeacher`
const API_SAGAHTOKEN = `${API_URL}/tokenUaSagah`
const API_DYNAMIC_UA = `${API_URL}/client/dynamicUA`
const API_USE_TIMELINE = `${API_HOST_TIMELINE_V2()}/v1/gaialite/timeline-integration/configuration`
const API_UPDATE_TIMELINE = `${API_HOST_TIMELINE()}/v1/update-timeline`
const API_UPDATE_TIMELINE_V2 = `${API_HOST_TIMELINE_V2()}/v1/gaialite/timeline-integration/timeline`

export default {
  API_HOST,
  API_KEY_TIMELINE,
  API_URL,
  API_SESSION,
  API_USER,
  API_AREAS,
  API_ORGANIZATION_CHART,
  API_UA,
  API_COURSES,
  API_FLOW,
  API_COORDINATORS,
  API_PROFESSORS,
  API_DISCIPLINES,
  API_SUGGEST,
  API_SAGAHTEACHERTOKEN,
  API_SAGAHTOKEN,
  API_DYNAMIC_UA,
  API_USE_TIMELINE,
  API_UPDATE_TIMELINE,
  API_UPDATE_TIMELINE_V2
}
