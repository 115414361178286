import professorsApi from '@/api/professors'
import flowApi from '@/api/flow'

export const SET_PROFESSORS = 'SET_PROFESSORS'
export const SET_SELECTED_PROFESSOR = 'SET_SELECTED_PROFESSOR'
export const SET_PROFESSORS_FLOW = 'SET_PROFESSORS_FLOW'

const state = {
  professors: [],
  selectedProfessor: {},
  professorsFlow: Boolean
}

const getters = {
  getterProfessorsFlow: (state) => state.professorsFlow
}

const mutations = {
  [SET_PROFESSORS] (state, values) {
    state.professors = values
  },
  [SET_SELECTED_PROFESSOR] (state, value) {
    state.selectedProfessor = value
  },
  [SET_PROFESSORS_FLOW] (state, value) {
    state.professorsFlow = value
  }
}

const actions = {
  actionGetProfessorsFlow ({ commit }) {
    return flowApi.getFlow()
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.response.data.message)
      })
  },
  actionUpdateProfessorsFlow ({ commit }, status) {
    const params = {
      flow: 'teachers',
      status
    }

    return flowApi.updateFlow(params)
      .then((response) => {
        commit(SET_PROFESSORS_FLOW, status)
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  },
  actionGetProfessors ({ commit }, params) {
    return professorsApi.getProfessors(params)
      .then((response) => {
        commit(SET_PROFESSORS, response.data)
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  },
  actionInsertProfessor ({ commit }, { name, email, disciplines }) {
    return professorsApi.insertProfessor({ name, email, disciplines })
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  },
  actionGetProfessor ({ commit }, params) {
    return professorsApi.getProfessor(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  },
  actionUpdateProfessor ({ commit }, params) {
    return professorsApi.updateProfessor(params)
      .then((response) => {
        commit(SET_SELECTED_PROFESSOR, response.data)
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  },
  actionDeleteProfessor ({ commit }, id) {
    return professorsApi.deleteProfessor(id)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
