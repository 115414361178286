export default {
  getIconStatus (status) {
    return {
      A: 'mdi-alert', // active
      S: 'mdi-flag', // sent
      N: 'mdi-alert', // new
      D: 'mdi-cancel', // disapprove
      X: 'mdi-check-circle' // approved
    }[status]
  },
  getTextStatus (status, motive = null) {
    if (status === 'D') {
      return `Disciplina com Revisões: ${motive}`
    } else {
      return {
        A: 'Disciplina em Configuração', // active
        S: 'Enviada para Avaliação', // sent
        N: 'Disciplina em Configuração', // new
        D: 'Disciplina com Revisões', // disapprove
        X: 'Disciplina Aprovada' // approved
      }[status] || 'Disciplina em Configuração'
    }
  },
  getColorStatus (status) {
    return {
      A: '#20A29B',
      S: '#FFC107',
      N: '#20A29B',
      D: '#FF5252',
      X: '#20A29B'
    }[status]
  }
}
