import accountApi from '@/api/account'
import { jwtDecode } from 'jwt-decode'

export const SET_ACCOUNT = 'SET_ACCOUNT'
export const CLEAR_ACCOUNT = 'CLEAR_ACCOUNT'
export const SET_STATUS_LOGIN = 'SET_STATUS_LOGIN'
export const SET_MIGRATION = 'SET_MIGRATION'

const state = {
  account: {}
}

const getters = {
  getterAccount: (state) => state.account
}

const mutations = {
  [SET_ACCOUNT] (state, values) {
    window.localStorage.setItem('token-sagah', values.token)

    const decodedToken = jwtDecode(values.token)

    state.account = {
      ...decodedToken,
      firstLogin: values.firstLogin
    }
  },
  [SET_STATUS_LOGIN] (state) {
    state.account = {
      ...state.account,
      firstLogin: false
    }
  },
  [SET_MIGRATION] (state) {
    state.account.isMigrationSafeA = false
  },
  [CLEAR_ACCOUNT] (state) {
    window.localStorage.removeItem('token-sagah')

    state.account = {}
  }
}

const actions = {
  actionSignIn ({ commit, dispatch }, params) {
    return accountApi.signIn(params.username, params.password)
      .then((response) => {
        const params = {
          token: response.data.token,
          firstLogin: response.data.firstLogin
        }

        commit(SET_ACCOUNT, params)

        dispatch('actionVerifyDynamicUA')
        const decoded = jwtDecode(response.data.token)

        if (decoded.clientGaia) {
          dispatch('actionVerifyUseTimeline', decoded.clientGaia)
        }

        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e.response.data)
      })
  },
  actionResetPassword (_, params) {
    return accountApi.resetPassword(params.userid, params.email)
      .then((response) => {
        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e.response.data.message)
      })
  },
  actionChangePassword ({ commit }, params) {
    return accountApi.changePassword(params.oldPassword, params.newPassword, params.newPasswordConfirmation)
      .then((response) => {
        commit(SET_STATUS_LOGIN)

        return Promise.resolve(response)
      })
      .catch((e) => {
        return Promise.reject(e.response.data.message)
      })
  },
  actionAlternateLogin ({ commit, dispatch }, params) {
    return accountApi.alternateSignIn(params)
      .then((response) => {
        commit(SET_ACCOUNT, response.data)

        dispatch('actionVerifyDynamicUA')

        dispatch('actionVerifyUseTimeline')

        return Promise.resolve(response.data)
      })
      .catch((error) => {
        return Promise.reject(error.response.data.message)
      })
  },
  actionReactivateUser (_, params) {
    return accountApi.reactivateUser(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        return Promise.reject(error.response.data.message)
      })
  },
  actionGetUserInfo (_, params) {
    return accountApi.getUserInfo(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        return Promise.reject(error.response.data.message)
      })
  },
  actionMigration ({ commit }, params) {
    return accountApi.migration(params)
      .then((response) => {
        commit(SET_MIGRATION)
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
