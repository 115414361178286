import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { createModule } from 'vuex-toast'
import 'vuex-toast/dist/vuex-toast.css'
import Account from '@/store/modules/account'
import Courses from '@/store/modules/courses'
import Coordinators from '@/store/modules/coordinators'
import Professors from '@/store/modules/professors'
import Disciplines from '@/store/modules/disciplines'
import Areas from '@/store/modules/areas'
import OrganizationChart from '@/store/modules/organizationChart'
import Uas from '@/store/modules/uas'
import SuggestContent from '@/store/modules/suggestContent'
import Client from '@/store/modules/client'
import Timeline from '@/store/modules/timeline'
import * as actions from './modules/cleanStorage'
Vue.use(Vuex)

export default new Vuex.Store({
  actions,
  modules: {
    Account,
    Courses,
    Coordinators,
    Professors,
    Disciplines,
    Areas,
    OrganizationChart,
    Uas,
    SuggestContent,
    Client,
    Timeline,
    toast: createModule({
      dismissInterval: 5000
    })
  },
  strict: false,
  plugins: [
    createPersistedState({
      key: 'vuex-sagah'
    })
  ]
})
