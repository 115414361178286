import { ADD_TOAST_MESSAGE } from 'vuex-toast'
import { mapActions } from 'vuex'
import simpleItemsList from '@/components/simple-items-list'
import itemsList from '@/components/items-list'
import modalStructure from '@/components/modals/modal-structure'
import disciplineModalStructure from '@/components/modals/discipline-search'
import { isEqual, cloneDeep, findIndex } from 'lodash'

export default {
  components: {
    simpleItemsList,
    itemsList,
    modalStructure,
    disciplineModalStructure
  },
  data () {
    return {
      useProfessorsFlow: null,
      inputProfessorName: '',
      inputProfessorEmail: '',
      page: 1,
      totalPages: 0,
      tableProfessorDisciplines: [],
      professors: [],
      professorInitialState: {
        id: '',
        name: '',
        disciplines: []
      },
      selectedProfessor: {
        id: '',
        name: '',
        disciplines: []
      },
      isLoadingUpdate: false,
      isLoadingProfessors: false,
      itemToDelete: null,
      professorToReactivate: {},
      alertModalSettings: {
        isOpen: false,
        icon: '',
        title: '',
        message: '',
        color: '',
        isPersistent: false,
        isConfirmLoading: false,
        textCancel: '',
        textConfirm: ''
      },
      disciplineToDelete: null,
      disciplineModalSettings: {
        isOpen: false,
        icon: '',
        title: '',
        message: '',
        color: '',
        isPersistent: false,
        isConfirmLoading: false,
        textCancel: '',
        textConfirm: ''
      },
      disciplineAlertModalSettings: {
        isOpen: false,
        icon: '',
        title: '',
        message: '',
        color: '',
        isPersistent: false,
        isConfirmLoading: false,
        textCancel: '',
        textConfirm: ''
      },
      professorReactivateModalSettings: {
        isOpen: false,
        icon: '',
        title: '',
        message: '',
        color: '',
        isPersistent: false,
        isConfirmLoading: false,
        textCancel: '',
        textConfirm: ''
      },
      search: '',
      sortType: 'asc',
      sortListTypes: [
        { name: 'Ordem alfabética crescente', value: 'asc' },
        { name: 'Ordem alfabética decrescente', value: 'desc' }
      ]
    }
  },
  mounted () {
    this.isLoadingProfessors = true
    this.getProfessors()
    this.getFlow()
  },
  methods: {
    ...mapActions([
      'actionGetProfessors',
      'actionGetProfessor',
      'actionInsertProfessor',
      'actionUpdateProfessor',
      'actionDeleteProfessor',
      'actionGetProfessorsFlow',
      'actionUpdateProfessorsFlow',
      'actionReactivateUser'
    ]),
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    getFlow () {
      this.actionGetProfessorsFlow()
        .then(response => {
          this.useProfessorsFlow = !response.teachers
        })
        .catch(message => {
          this.addToast({
            text: message,
            type: 'danger'
          })
        })
    },
    updateFlow () {
      this.actionUpdateProfessorsFlow(!this.useProfessorsFlow)
        .then(response => {
          this.addToast({
            text: response.message,
            type: 'success'
          })
        })
        .catch(message => {
          this.addToast({
            text: message,
            type: 'danger'
          })
        })
    },
    getProfessor (item) {
      this.actionGetProfessor({ id: item.id })
        .then((response) => {
          Object.assign(this.selectedProfessor, response)
          this.professorInitialState = cloneDeep(response)
        })
        .catch(message => {
          this.addToast({
            text: message,
            type: 'danger'
          })
        })
    },
    insertProfessor (item) {
      if (!item.name || !item.email) {
        return
      }

      this.isLoadingUpdate = true
      const { name, email, disciplines } = item
      this.actionInsertProfessor({ name, email, disciplines })
        .then((response) => {
          this.professors.push(response)
          this.addToast({
            text: 'Professor adicionado com sucesso!',
            type: 'success'
          })
          this.clearFields()
        })
        .catch(message => {
          this.addToast({
            text: message,
            type: 'danger'
          })
        })
        .finally(() => {
          this.isLoadingUpdate = false
        })
    },
    updateProfessor () {
      if (!this.selectedProfessor.name || !this.selectedProfessor.email) {
        return
      }

      this.isLoadingUpdate = true
      this.actionUpdateProfessor(this.selectedProfessor)
        .then(() => {
          const professorToReplace = this.professors.find((professor) => professor.id === this.selectedProfessor.id)

          Object.assign(professorToReplace, this.selectedProfessor)

          this.addToast({
            text: 'Professor atualizado com sucesso!',
            type: 'success'
          })

          this.selectedProfessor = {
            id: '',
            name: ''
          }

          this.clearFields()
        })
        .catch(message => {
          this.addToast({
            text: message,
            type: 'danger'
          })
        })
        .finally(() => {
          this.isLoadingUpdate = false
        })
    },
    deleteItem (id) {
      this.alertModalSettings.isConfirmLoading = true
      this.actionDeleteProfessor(id)
        .then((response) => {
          this.alertModalSettings.isOpen = false

          if (response.message === 'O Professor foi desativado por conter Unidades personalizadas.') {
            const index = findIndex(this.professors, (professor) => professor.id === id)

            if (index > -1) {
              this.professors[index].active = false
            }
          } else {
            this.professors = this.professors.filter((professor) => professor.id !== id)
          }

          this.addToast({
            text: response.message,
            type: 'success'
          })

          if (id === this.selectedProfessor.id) {
            this.clearFields()
          }
        })
        .catch(message => {
          this.addToast({
            text: message,
            type: 'danger'
          })
        })
        .finally(() => {
          this.alertModalSettings.isConfirmLoading = false
        })
    },
    deleteDisciplineItem (id) {
      this.selectedProfessor.disciplines = this.selectedProfessor.disciplines.filter((disc) => disc.id !== id)
      this.closeAlertDisciplineModal()
    },
    getProfessors () {
      this.isLoadingProfessors = true

      const params = {
        search: this.search || '',
        sort: this.sortType,
        page: this.page,
        rowsPerPage: 20
      }

      this.actionGetProfessors(params)
        .then((response) => {
          this.professors = response.teachers

          this.totalPages = Math.ceil(response.total / 20)
        })
        .catch(message => {
          this.addToast({
            text: message,
            type: 'danger'
          })
        })
        .finally(() => {
          this.isLoadingProfessors = false
        })
    },
    filterItems (query) {
      this.page = 1
      this.search = query
      this.getProfessors()
    },
    sortItems (type) {
      this.page = 1
      this.sortType = type
      this.getProfessors()
    },
    changePagination (page) {
      this.page = page
      this.getProfessors()
    },
    clearFields () {
      this.selectedProfessor = {
        id: '',
        name: '',
        disciplines: []
      }
      this.inputProfessorEmail = ''
      this.inputProfessorName = ''
      this.tableProfessorDisciplines = []
    },
    openConfirmModalDiscipline (item) {
      this.disciplineToDelete = item.id
      this.disciplineAlertModalSettings = {
        isOpen: true,
        icon: '',
        color: 'primary',
        isPersistent: true,
        message: `<span>Confirma a exclusão da Disciplina do Professor: <b>${item.name}</b></span>`,
        title: 'Confirmação de Exclusão',
        isConfirmLoading: false,
        textCancel: 'Cancelar',
        textConfirm: 'Excluir'
      }
    },
    openConfirmModal (item) {
      this.itemToDelete = item.id
      this.alertModalSettings = {
        isOpen: true,
        icon: '',
        color: 'primary',
        isPersistent: true,
        message: `<span>Confirma a exclusão do Professor: <b>${item.name}</b></span>`,
        title: 'Confirmação de Exclusão',
        isConfirmLoading: false,
        textCancel: 'Cancelar',
        textConfirm: 'Excluir'
      }
    },
    openAddDisciplineModal () {
      this.disciplineModalSettings = {
        isOpen: true,
        icon: '',
        title: 'Selecione uma disciplina'
      }
    },
    selectedDiscipline ({ disciplineid, name }) {
      const existsDiscipline = this.selectedProfessor.disciplines.find(x => x.id === disciplineid)
      if (!existsDiscipline) {
        this.selectedProfessor.disciplines.push({
          id: disciplineid,
          name
        })
      }
    },
    closeAlertDisciplineModal () {
      this.disciplineAlertModalSettings.isOpen = false
      this.disciplineAlertModalSettings.isConfirmLoading = false
    },
    closeDisciplineModal () {
      this.saveChangesReminder()
      this.disciplineModalSettings.isOpen = false
      this.disciplineModalSettings.isConfirmLoading = false
    },
    closeAlertModal () {
      this.alertModalSettings.isOpen = false
      this.alertModalSettings.isConfirmLoading = false
    },
    saveChangesReminder () {
      if (!isEqual(this.selectedProfessor, this.professorInitialState)) {
        this.addToast({
          text: 'Não se esqueça de salvar as alterações!',
          type: 'warning'
        })
      }
    },
    reactiveAccount (user) {
      this.professorToReactivate = user

      this.professorReactivateModalSettings = {
        isOpen: true,
        icon: '',
        title: 'Reativar Professor',
        message: `<span>Deseja reativar o Professor <b> ${this.professorToReactivate.name}</b>?</span>`,
        color: 'primary',
        isPersistent: true,
        isConfirmLoading: false,
        textCancel: 'Cancelar',
        textConfirm: 'Reativar'
      }
    },
    closeModalReactivateProfessor () {
      this.professorToReactivate = {}

      this.professorReactivateModalSettings = {
        isOpen: false,
        icon: '',
        title: '',
        message: '',
        color: '',
        isPersistent: false,
        isConfirmLoading: false,
        textCancel: '',
        textConfirm: ''
      }
    },
    confirmReactivateProfessor () {
      this.professorReactivateModalSettings.isConfirmLoading = true

      this.actionReactivateUser(this.professorToReactivate.id)
        .then((result) => {
          const index = findIndex(this.professors, (professor) => professor.id === this.professorToReactivate.id)

          if (index > -1) {
            this.professors[index].active = true
          }

          this.addToast({
            text: result.message,
            type: 'success'
          })

          this.closeModalReactivateProfessor()
        }).catch((err) => {
          this.professorReactivateModalSettings.isConfirmLoading = false

          this.addToast({
            text: err,
            type: 'danger'
          })
        })
    }
  }
}
