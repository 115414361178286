import { mapActions } from 'vuex'
import { ADD_TOAST_MESSAGE } from 'vuex-toast'
import modalStructure from '@/components/modals/modal-structure'

export default {
  props: [
    'openRequestDisciplineModal'
  ],
  components: {
    modalStructure
  },
  data () {
    return {
      courses: [],
      disciplineName: '',
      isButtonLoading: false,
      selectedCourse: {
        id: '',
        name: ''
      }
    }
  },
  mounted () {
    this.getCourses()
  },
  methods: {
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    ...mapActions([
      'actionGetCourses',
      'actionAskCreateDiscipline'
    ]),
    addDiscipline () {
      this.isButtonLoading = true

      const params = {
        disciplineName: this.disciplineName,
        course: this.selectedCourse
      }

      this.actionAskCreateDiscipline(params)
        .then((response) => {
          this.isButtonLoading = false

          this.addToast({
            text: response.message,
            type: 'success'
          })

          this.closeRequestDisciplineModal()
        })
        .catch((message) => {
          this.isButtonLoading = false

          this.addToast({
            text: message,
            type: 'danger'
          })
        })
    },
    getCourses () {
      const params = {
        search: '',
        sort: 'asc'
      }

      this.actionGetCourses(params)
        .then((response) => {
          this.courses = response
        })
        .catch(() => {
          this.addToast({
            text: 'Não foi possível buscar os cursos. Tente novamente mais tarde.',
            type: 'danger'
          })
        })
    },
    closeRequestDisciplineModal () {
      this.$emit('emit-close-modal')
    }
  }
}
