import itemsListSelect from '@/components/items-list-select'
import { mapActions } from 'vuex'

export default {
  components: {
    itemsListSelect
  },
  props: [
    'openModal',
    'modalPersistent',
    'icon',
    'title',
    'message',
    'backgroundColor',
    'textConfirm',
    'textCancel',
    'isConfirmButtonLoading'
  ],
  data () {
    return {
      isLoadingList: false,
      disciplines: [],
      search: '',
      page: 1,
      totalPages: 0,
      sortItemBy: 'name',
      sortItemDirection: 'asc',
      orderListTypes: [
        { name: 'Ordem alfabética crescente', value: 'asc' },
        { name: 'Ordem alfabética decrescente', value: 'desc' },
        { name: 'Por cursos', value: 'course' }
      ]
    }
  },
  mounted () {
    this.getDisciplines()
  },
  methods: {
    ...mapActions([
      'actionGetDisciplines'
    ]),
    getDisciplines () {
      this.isLoadingList = true

      const params = {
        search: this.search || '',
        sortBy: this.sortItemBy,
        sortDirection: this.sortItemDirection,
        page: this.page,
        rowsPerPage: 20
      }

      this.actionGetDisciplines(params)
        .then((response) => {
          response.disciplines.forEach((item, index) => {
            item.id = `${index}-${item.disciplineid}-${item.courseid}`
          })

          this.totalPages = Math.ceil(response.total / 20)
          this.disciplines = response.disciplines

          this.isLoadingList = false
        })
    },
    filterItems (query) {
      this.search = query
      this.getDisciplines()
    },
    changePagination (page) {
      this.page = page
      this.getDisciplines()
    },
    selectDiscipline (item) {
      this.disciplines = this.disciplines.filter(
        disc => disc.id !== item.id
      )

      this.$emit('emit-select-discipline', item)
    },
    sortList (order) {
      this.page = 1

      if (order === 'asc' || order === 'desc') {
        this.sortItemDirection = order

        this.sortItemBy = 'name'
      } else {
        this.sortItemDirection = 'asc'

        this.sortItemBy = order
      }
      this.getDisciplines()
    },
    cancel () {
      this.$emit('emit-cancel')
    }
  },
  computed: {
    iconName () {
      return `mdi-${this.icon}`
    },
    showModal () {
      return this.openModal
    }
  }
}
