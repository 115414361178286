import uaApi from '@/api/ua'

const state = {
}

const getters = {
}

const mutations = {
}

const actions = {
  actionGetUas (_, params) {
    return uaApi.getUas(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.response.data)
      })
  },
  actionGetCountUas () {
    return uaApi.getCountUas()
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.response.data)
      })
  },
  actionGetUasByName (_, params) {
    return uaApi.getUasByName(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.response.data)
      })
  },
  actionGenerateUrlUaSagah (_, params) {
    return uaApi.generateUrlUaSagah(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  actionGenerateUrlUaTeacher (_, params) {
    return uaApi.generateUrlUaTeacher(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  actionDeleteUAs (_, params) {
    return uaApi.deleteUnits(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  },
  actionRedirectToEditInSagahCM (_, params) {
    return uaApi.redirectToEditInSagahCM(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e)
      })
  },
  actionGetUnits (_, params) {
    return uaApi.getUnits(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.data.message)
      })
  },
  actionRedirectToSagahCm (_, params) {
    return uaApi.redirectToSagahCm(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.response.data.message)
      })
  },
  actionRedirectTeacherToSagahCm (_, params) {
    return uaApi.redirectTeacherToSagahCm(params)
      .then((response) => {
        return Promise.resolve(response.data)
      })
      .catch((e) => {
        return Promise.reject(e.response.data.message)
      })
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
