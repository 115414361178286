import { mapActions, mapGetters, mapMutations } from 'vuex'
import modalStructure from '@/components/modals/modal-structure'
import { findIndex } from 'lodash'
import draggable from 'vuedraggable'
import { ADD_TOAST_MESSAGE } from 'vuex-toast'
import statusHelper from '@/helpers/status.js'
import modalUpdateLimitOfDisciplines from '@/components/modals/update-limit-uas-of-disciplines'
import modalDisciplineReturnReason from '@/components/modals/discipline-return-reason'
import htmlHelper from '@/helpers/html'

export default {
  data () {
    return {
      isLoadingDisciplines: false,
      isLoadingUas: false,
      isOpenDisapproveModal: false,
      totalPages: null,
      totalLimitUasOfDiscipline: null,
      disciplines: [],
      indexExpansionPanel: [],
      selectedItem: {},
      page: 1,
      searchedDisciplines: '',
      itemToDelete: {},
      disciplineToDelete: {},
      disciplineToApprove: {},
      disciplineToDisapprove: {},
      deleteModalSettings: {
        isOpen: false,
        icon: '',
        title: '',
        message: '',
        color: '',
        isPersistent: false,
        isConfirmLoading: false,
        textCancel: '',
        textConfirm: ''
      },
      deleteDisciplineModalSettings: {
        isOpen: false,
        icon: '',
        title: '',
        message: '',
        color: '',
        isPersistent: false,
        isConfirmLoading: false,
        textCancel: '',
        textConfirm: ''
      },
      confirmApproveDisciplineModalSettings: {
        isOpen: false,
        icon: '',
        title: '',
        message: '',
        color: '',
        isPersistent: false,
        isConfirmLoading: false,
        textCancel: '',
        textConfirm: ''
      },
      askApproveDisciplineModalSettings: {
        isOpen: false,
        icon: '',
        title: '',
        message: '',
        color: '',
        isPersistent: false,
        isConfirmLoading: false,
        textCancel: '',
        textConfirm: ''
      }
    }
  },
  components: {
    modalStructure,
    draggable,
    modalUpdateLimitOfDisciplines,
    modalDisciplineReturnReason
  },
  watch: {
    getterSearchDiscipline () {
      this.page = 1

      this.searchedDisciplines = this.getterSearchDiscipline

      this.getDisciplines()
    },
    getterChangeTotalLimit () {
      this.updateAllDisciplines(this.getterChangeTotalLimit)
    }
  },
  computed: {
    ...mapGetters([
      'getterAccount',
      'getterSearchDiscipline',
      'getterChangeTotalLimit'
    ])
  },
  mounted () {
    this.getDisciplines()
  },
  methods: {
    ...mapActions([
      'actionGetDisciplinesUnapproved',
      'actionGetUasByDisciplineId',
      'actionDeleteUaOfDiscipline',
      'actionOrderUasOfDisciplines',
      'actionUpdateUasLimitOfDisciplines',
      'actionDeleteAskExclusionDiscipline',
      'actionPutApproveDiscipline',
      'actionPutAskApproveDiscipline',
      'actionRedirectToSagahCm',
      'actionRedirectTeacherToSagahCm'
    ]),
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    ...mapMutations([
      'SET_CHANGE_TOTAL_LIMIT'
    ]),
    getDisciplines (resetExpansions = false) {
      this.goToTop()

      this.selectedItem = {}

      if (resetExpansions) {
        this.indexExpansionPanel = []
      }

      this.isLoadingDisciplines = true

      const params = {
        page: this.page,
        search: this.getterSearchDiscipline,
        rowsPerPage: 20,
        sortDirection: ''
      }

      this.actionGetDisciplinesUnapproved(params)
        .then((result) => {
          this.isLoadingDisciplines = false

          result.disciplines.forEach(item => {
            this.$set(item, 'isLoadingUas', false)
            this.$set(item, 'isLoadingOrdenate', false)
            this.$set(item, 'isLoadingUpdateLimit', false)
          })

          this.disciplines = result.disciplines

          this.totalPages = Math.ceil(result.total / 20)
        }).catch(() => {
          this.isLoadingDisciplines = false
        })
    },
    setSelectedDiscipline (discipline) {
      if (!(Object.entries(this.selectedItem).length === 0 && this.selectedItem.constructor === Object)) {
        if (discipline !== this.selectedItem) {
          this.addToast({
            text: 'Você selecionou um item diferente, salve as operações anteriores ou cancele.',
            type: 'warning'
          })
        }
      } else {
        this.selectedItem = discipline
        this.totalLimitUasOfDiscipline = discipline.uaslimit
      }
    },
    cancelSaveLimitUaOfDiscipline (discipline) {
      this.selectedItem = {}

      discipline.uaslimit = this.totalLimitUasOfDiscipline
    },
    saveLimitUaOfDiscipline (discipline, index) {
      if (!discipline.uaslimit) {
        this.addToast({
          text: 'Necessário informar a quantidade limite de unidades',
          type: 'warning'
        })
      } else if (discipline.uaslimit < discipline.uastotal) {
        this.addToast({
          text: 'O limite de unidades é inferior ao de unidades utilizadas. Exclua unidades desta disciplina antes de diminuir o limite.',
          type: 'warning'
        })

        this.selectedItem.uaslimit = this.totalLimitUasOfDiscipline
      } else if (discipline.uaslimit === this.totalLimitUasOfDiscipline) {
        this.selectedItem = {}
        return false
      } else {
        const params = {
          id: discipline.disciplineid,
          total: this.selectedItem.uaslimit
        }

        this.disciplines[index].isLoadingUpdateLimit = true

        this.actionUpdateUasLimitOfDisciplines(params)
          .then(() => {
            this.disciplines[index].isLoadingUpdateLimit = false
            this.selectedItem = {}
          }).catch(() => {
            this.addToast({
              text: 'Houve um erro ao alterar o limite de unidades na disciplina, tente novamente mais tarde.',
              type: 'warning'
            })

            this.disciplines[index].isLoadingUpdateLimit = false
            this.selectedItem = {}
          })
      }
    },
    getIcon (status) {
      return statusHelper.getIconStatus(status)
    },
    getColor (status) {
      return statusHelper.getColorStatus(status)
    },
    getNameIcon (status, motive = null) {
      if (motive) {
        return statusHelper.getTextStatus(status, motive)
      } else {
        return statusHelper.getTextStatus(status)
      }
    },
    getUasByDisciplineId (discipline, index) {
      this.disciplines[index].isLoadingUas = true

      if (!this.indexExpansionPanel.includes(index)) {
        this.actionGetUasByDisciplineId(discipline.disciplineid)
          .then((result) => {
            this.disciplines[index].isLoadingUas = false
            this.$set(this.disciplines[index], 'uas', result)
            this.scrollIntoView(discipline.disciplineid)
          }).catch(() => {
            this.disciplines[index].isLoadingUas = false
          })
      }
    },
    changeOrder (discipline, index) {
      discipline.uas.forEach((item, index) => {
        item.position = index
      })

      const params = {
        disciplineid: discipline.disciplineid,
        uas: discipline.uas
      }

      this.disciplines[index].isLoadingOrdenate = true

      this.actionOrderUasOfDisciplines(params)
        .then(() => {
          this.disciplines[index].isLoadingOrdenate = false
        }).catch(() => {
          this.disciplines[index].isLoadingOrdenate = false
          this.addToast({
            text: 'Houve um erro ao ordenar as unidades, por favor tente novamente mais tarde.',
            type: 'danger'
          })
        })
    },
    showModalDeleteUa (discipline, ua) {
      this.itemToDelete = {
        idDiscipline: discipline.disciplineid,
        disciplineuaid: ua.disciplineuaid
      }

      this.deleteModalSettings = {
        isOpen: true,
        icon: '',
        color: 'primary',
        isPersistent: true,
        message: `<span>Deseja realmente excluir a unidade <b>${ua.name}</b>?</span>`,
        title: 'Confirmação de Exclusão',
        isConfirmLoading: false,
        textCancel: 'Cancelar',
        textConfirm: 'Excluir'
      }
    },
    closeDeleteUaModal () {
      this.deleteModalSettings.isOpen = false
      this.deleteModalSettings.isConfirmLoading = false
    },
    deleteUaOfDiscipline () {
      this.deleteModalSettings.isConfirmLoading = true

      this.actionDeleteUaOfDiscipline(this.itemToDelete)
        .then(() => {
          this.disciplines.forEach((discipline, index) => {
            if (discipline.hasOwnProperty('uas') && this.itemToDelete.idDiscipline === discipline.disciplineid) {
              const indexUa = findIndex(discipline.uas, (discipline) => {
                return discipline.disciplineuaid === this.itemToDelete.disciplineuaid
              })

              if (indexUa > -1) {
                this.disciplines[index].uas.splice(indexUa, 1)
              }

              this.disciplines[index].uastotal = this.disciplines[index].uastotal - 1
            }
          })

          this.itemToDelete = {}
          this.deleteModalSettings.isConfirmLoading = false
          this.deleteModalSettings.isOpen = false
        }).catch(() => {
          this.deleteModalSettings.isConfirmLoading = false
          this.deleteModalSettings.isOpen = false
        })
    },
    scrollIntoView (id) {
      const element = document.getElementById(id)
      element.scrollIntoView({ behavior: 'smooth' })
    },
    goToTop () {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      })
    },
    updateAllDisciplines (total) {
      this.disciplines.forEach((discipline) => {
        if (discipline.uaslimit === 0 || discipline.uaslimit === '0') {
          discipline.uaslimit = total
        }
      })

      this.SET_CHANGE_TOTAL_LIMIT(null)
    },
    openModalDeleteDiscipline (discipline) {
      this.deleteDisciplineModalSettings = {
        isOpen: true,
        icon: '',
        color: 'primary',
        isPersistent: true,
        message: `<span>Deseja prosseguir com a solicitação de exclusão da disciplina <b>${discipline.name}</b>?</span>`,
        title: 'Confirmação de Exclusão',
        isConfirmLoading: false,
        textCancel: 'Cancelar',
        textConfirm: 'Excluir'
      }

      this.disciplineToDelete = discipline
    },
    deleteDiscipline () {
      this.deleteDisciplineModalSettings.isConfirmLoading = true

      const params = {
        disciplineId: this.disciplineToDelete.disciplineid
      }

      this.actionDeleteAskExclusionDiscipline(params)
        .then(() => {
          this.addToast({
            text: 'Solicitação de exclusão enviada com sucesso.',
            type: 'success'
          })

          this.deleteDisciplineModalSettings.isConfirmLoading = false
          this.deleteDisciplineModalSettings.isOpen = false
          this.disciplineToDelete = {}
        }).catch((error) => {
          this.addToast({
            text: `Houve um erro ao solicitar a exclusão da disciplina, ${error}`,
            type: 'danger'
          })

          this.deleteDisciplineModalSettings.isConfirmLoading = false
          this.deleteDisciplineModalSettings.isOpen = false
        })
    },
    openModalApproveDiscipline (discipline) {
      this.confirmApproveDisciplineModalSettings = {
        isOpen: true,
        icon: '',
        color: 'primary',
        isPersistent: true,
        message: `<span>Confirmar a aprovação da disciplina <b>${discipline.name}</b>?</span>`,
        title: 'Aprovar Disciplina',
        isConfirmLoading: false,
        textCancel: 'Cancelar',
        textConfirm: 'Confirmar'
      }

      this.disciplineToApprove = discipline
    },
    openModalAskApproveDiscipline (discipline) {
      this.askApproveDisciplineModalSettings = {
        isOpen: true,
        icon: '',
        color: 'primary',
        isPersistent: true,
        message: `<span>Solicitar a aprovação da disciplina <b>${discipline.name}</b>?</span>`,
        title: 'Solicitar Aprovação',
        isConfirmLoading: false,
        textCancel: 'Cancelar',
        textConfirm: 'Solicitar'
      }

      this.disciplineToApprove = discipline
    },
    approveDiscipline () {
      this.confirmApproveDisciplineModalSettings.isConfirmLoading = true

      const params = {
        disciplineId: this.disciplineToApprove.disciplineid
      }

      this.actionPutApproveDiscipline(params)
        .then((result) => {
          const index = findIndex(this.disciplines, (discipline) => discipline.disciplineid === result.discipline.id)

          if (index > -1) {
            this.disciplines.splice(index, 1)
          }

          this.confirmApproveDisciplineModalSettings.isOpen = false
          this.confirmApproveDisciplineModalSettings.isConfirmLoading = false

          this.addToast({
            text: result.message,
            type: 'success'
          })
        }).catch((error) => {
          this.addToast({
            text: error.message,
            type: 'danger'
          })

          this.confirmApproveDisciplineModalSettings.isOpen = false
          this.confirmApproveDisciplineModalSettings.isConfirmLoading = false
        })
    },
    askApproveDiscipline () {
      this.askApproveDisciplineModalSettings.isConfirmLoading = true

      const params = {
        disciplineId: this.disciplineToApprove.disciplineid
      }

      this.actionPutAskApproveDiscipline(params)
        .then(() => {
          const index = findIndex(this.disciplines, (discipline) => discipline.disciplineid === this.disciplineToApprove.disciplineid)

          if (index > -1) {
            this.disciplines[index].status = 'S'
          }

          this.addToast({
            text: 'Disciplina enviada para aprovação.',
            type: 'success'
          })

          this.askApproveDisciplineModalSettings.isOpen = false
          this.askApproveDisciplineModalSettings.isConfirmLoading = false
        }).catch(() => {
          this.addToast({
            text: 'Houve um erro ao solicitar a aprovação da disciplina, por favor tente mais tarde.',
            type: 'danger'
          })

          this.askApproveDisciplineModalSettings.isOpen = false
          this.askApproveDisciplineModalSettings.isConfirmLoading = false
        })
    },
    disapproveDiscipline (discipline) {
      this.disciplineToDisapprove = discipline
      this.isOpenDisapproveModal = true
    },
    confirmDisapproveDiscipline (reason) {
      const index = findIndex(this.disciplines, (discipline) => discipline.disciplineid === this.disciplineToDisapprove.disciplineid)

      this.isOpenDisapproveModal = false

      if (index > -1) {
        this.disciplines[index].status = 'D'
        this.disciplines[index].motive = reason
      }
    },
    redirectToSagahCm (ua) {
      if (ua.uauserid) {
        const params = {
          uaId: ua.uaid,
          version: ua.version,
          uaIdentifier: ua.uaidentifier,
          uaUserId: ua.uauserid,
          marketplacecompositeobjecstid: ua.objectid,
          usuarioId: ua.usuarioid
        }

        this.actionRedirectTeacherToSagahCm(params)
          .then((result) => {
            window.open(result.url, '_blank')
          }).catch(() => {
            this.addToast({
              text: 'Houve um erro ao redirecionar para o SagahCM, por favor tente mais tarde.',
              type: 'danger'
            })
          })
      } else {
        const params = {
          uaId: ua.uaid
        }

        this.actionRedirectToSagahCm(params)
          .then((result) => {
            window.open(result.url, '_blank')
          }).catch(() => {
            this.addToast({
              text: 'Houve um erro ao redirecionar para o SagahCM, por favor tente mais tarde.',
              type: 'danger'
            })
          })
      }
    },
    removeTagsHtml (str) {
      return htmlHelper.removeTagsHtml(str)
    },
    decodeUtf (str) {
      return htmlHelper.decode(str)
    }
  }
}
