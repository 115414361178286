import { mapActions } from 'vuex'

export default {
  data () {
    return {
    }
  },
  components: {
  },
  mounted () {
    if (this.$route.params.username && this.$route.params.password) {
      this.validateUsernamePassword()
    }
  },
  methods: {
    ...mapActions([
      'actionAlternateLogin'
    ]),
    validateUsernamePassword () {
      const params = {
        userLogin: this.$route.params.username,
        passLogin: this.$route.params.password
      }

      this.actionAlternateLogin(params)
        .then(() => {
          this.$router.push({ name: 'Home' })
        }).catch(() => {
          this.$router.push({ name: 'Login' })
        })
    }
  }
}
