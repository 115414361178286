import { ADD_TOAST_MESSAGE } from 'vuex-toast'
import itemsList from '@/components/items-list/'
import modalStructure from '@/components/modals/modal-structure/'
import { mapActions } from 'vuex'

export default {
  components: {
    itemsList,
    modalStructure
  },
  data () {
    return {
      disciplines: [],
      courses: [],
      isLoadingBtn: false,
      isLoadingList: false,
      search: '',
      page: 1,
      totalPages: 0,
      sortItemBy: 'name',
      sortItemDirection: 'asc',
      orderListTypes: [
        { name: 'Ordem alfabética crescente', value: 'asc' },
        { name: 'Ordem alfabética decrescente', value: 'desc' },
        { name: 'Por cursos', value: 'course' }
      ],
      selectedDiscipline: {
        disciplineid: '',
        name: '',
        courseid: '',
        course: '',
        id: ''
      },
      alertModalSettings: {
        isOpen: false,
        icon: '',
        title: '',
        message: '',
        color: '',
        isPersistent: false,
        isConfirmLoading: false,
        textCancel: '',
        textConfirm: ''
      }
    }
  },
  mounted () {
    this.getDisciplines()

    this.getCourses()
  },
  methods: {
    ...mapActions([
      'actionGetDisciplines',
      'actionInsertDiscipline',
      'actionUpdateDiscipline',
      'actionDeleteDiscipline',
      'actionGetCourses'
    ]),
    ...mapActions({
      addToast: ADD_TOAST_MESSAGE
    }),
    getCourses () {
      const params = {
        search: '',
        sort: 'asc'
      }

      this.actionGetCourses(params)
        .then((response) => {
          this.courses = response
        })
    },
    getDisciplines () {
      this.isLoadingList = true

      const params = {
        search: this.search || '',
        sortBy: this.sortItemBy,
        sortDirection: this.sortItemDirection,
        page: this.page,
        rowsPerPage: 20
      }

      this.actionGetDisciplines(params)
        .then((response) => {
          response.disciplines.forEach((item, index) => {
            item.id = `${index}-${item.disciplineid}-${item.courseid}`
          })

          this.totalPages = Math.ceil(response.total / 20)
          this.disciplines = response.disciplines

          this.isLoadingList = false
        })
    },
    insertDiscipline () {
      this.isLoadingBtn = true

      this.actionInsertDiscipline(this.selectedDiscipline)
        .then((response) => {
          this.disciplines.push(response)

          this.addToast({
            text: 'Disciplina adicionada com sucesso!',
            type: 'success'
          })

          this.clearFields()
        })
        .catch(message => {
          this.addToast({
            text: message,
            type: 'danger'
          })
        })
        .finally(() => {
          this.isLoadingBtn = false
        })
    },
    editDiscipline (item) {
      this.$refs.input.focus()

      Object.assign(this.selectedDiscipline, item)
    },
    updateDiscipline () {
      this.isLoadingBtn = true

      this.actionUpdateDiscipline(this.selectedDiscipline)
        .then(() => {
          const disciplineToUpdate = this.disciplines.find((discipline) => discipline.id === this.selectedDiscipline.id)

          Object.assign(disciplineToUpdate, this.selectedDiscipline)

          this.addToast({
            text: 'Disciplina atualizada com sucesso!',
            type: 'success'
          })

          this.clearFields()
        })
        .catch(message => {
          this.addToast({
            text: message,
            type: 'danger'
          })
        })
        .finally(() => {
          this.isLoadingBtn = false
        })
    },
    deleteDiscipline (item) {
      this.alertModalSettings.isConfirmLoading = true

      this.actionDeleteDiscipline(item.disciplineid)
        .then((response) => {
          this.alertModalSettings.isOpen = false

          this.disciplines = this.disciplines.filter((discipline) => discipline.id !== item.id)

          this.addToast({
            text: response.message,
            type: 'success'
          })

          if (item.id === this.selectedDiscipline.id) {
            this.clearFields()
          }
        })
        .catch(message => {
          this.addToast({
            text: message,
            type: 'danger'
          })
        })
        .finally(() => {
          this.alertModalSettings.isConfirmLoading = false
        })
    },
    openConfirmModal (item) {
      this.itemToDelete = item

      this.alertModalSettings = {
        isOpen: true,
        icon: '',
        color: 'primary',
        isPersistent: true,
        message: `<span>A exclusão da disciplina também exclui os vínculos com as unidades. Confirma a exclusão da Disciplina: <b>${item.name}</b>? </span>`,
        title: 'Confirmação de Exclusão',
        isConfirmLoading: false,
        textCancel: 'Cancelar',
        textConfirm: 'Excluir'
      }
    },
    clearFields () {
      this.selectedDiscipline = {
        disciplineid: '',
        name: '',
        courseid: '',
        course: '',
        id: ''
      }
    },
    closeAlertModal () {
      this.isLoadingBtn = false

      this.alertModalSettings.isOpen = false

      this.alertModalSettings.isConfirmLoading = false
    },
    sortList (order) {
      this.page = 1

      if (order === 'asc' || order === 'desc') {
        this.sortItemDirection = order

        this.sortItemBy = 'name'
      } else {
        this.sortItemDirection = 'asc'

        this.sortItemBy = order
      }

      this.getDisciplines()
    },
    filterItems (query) {
      this.page = 1
      this.search = query
      this.getDisciplines()
    },
    changePagination (page) {
      this.page = page
      this.getDisciplines()
    },
    findCourse (courseid) {
      if (courseid) {
        const selectedCourse = this.courses.find(c => c.id === courseid)
        this.selectedDiscipline.course = selectedCourse.name
      }
    }
  }
}
