import Vue from 'vue'
import axios from 'axios'
import Const from '@/helpers/const'

export const refreshToken = (originalRequest) => {
  if (originalRequest.headers.hasOwnProperty('Authorization')) {
    return axios.post(`${Const.API_SESSION}/revalidate`, null, {
      headers: {
        Authorization: originalRequest.headers.Authorization
      }
    })
  }
  /* eslint-disable-next-line */
  return Promise.reject()
}

export const custAxios = () => {
  const instance = axios.create({
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem('token-sagah')}`
    }
  })

  instance.interceptors.response.use(async (config) => config, async (error) => {
    try {
      if (error.response.status === 401) {
        if (error.response.data.message && error.response.data.message.includes('Invalid Token')) {
          Vue.prototype.$globalStore.dispatch('cleanStorage')
          throw error
        }

        throw error
      } else {
        return Promise.reject(error.response)
      }
    } catch (err) {
      if (error.response.status === 401 && err.response.config.url.includes('/revalidate')) {
        Vue.prototype.$globalStore.dispatch('cleanStorage')
      }

      return Promise.reject(error.response)
    }
  })

  return instance
}
